.careers-advantages {
    width: 1300px;
    margin: 0 auto;
    margin-bottom: 200px;
  }
  @media (max-width: 1919px) {
    .careers-advantages {
      width: 1060px;
      margin-bottom: 150px;
    }
  }
  @media (max-width: 1365px) {
    .careers-advantages {
      position: relative;
      width: 700px;
      margin-bottom: 100px;
    }
  }
  @media (max-width: 767px) {
    .careers-advantages {
      margin-top: 90px;
      width: 328px;
      margin-bottom: 70px;
    }
  }
  .careers-advantages-title {
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 150px;
    line-height: 203px;
    margin: 205px 5px 70px 0;
  }
  @media (max-width: 1919px) {
    .careers-advantages-title {
      width: 900px;
      margin: 155px 0 70px 0;
      font-size: 110px;
      line-height: 149px;
    }
  }
  @media (max-width: 1365px) {
    .careers-advantages-title {
      margin: 100px 0 50px 0;
      width: 600px;
      font-size: 70px;
      line-height: 95px;
    }
  }
  @media (max-width: 767px) {
    .careers-advantages-title {
      margin: 67px 0 50px 0;
      width: 280px;
      font-size: 60px;
      line-height: 81px;
    }
  }
  .careers-advantages-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .careers-pros-card {
    position: relative;
    width: 310px;
    height: 300px;
    margin-bottom: 20px;
    background-color: #FFFFFF;
    overflow: hidden;
  }
  .careers-pros-card::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 420px;
    height: 2px;
    background-color: #303030;
  }
  @media (max-width: 1919px) {
    .careers-pros-card {
      margin-top: 1px;
      width: 250px;
      height: 250px;
    }
  }
  @media (max-width: 1365px) {
    .careers-pros-card {
      width: 340px;
      height: 200px;
    }
  }
  @media (max-width: 767px) {
    .careers-pros-card {
      margin-top: 0;
      width: 328px;
      height: 200px;
    }
  }
  .careers-pros-card-none-border {
    border: none;
    background-color: #FDFDFD;
  }
  .careers-pros-card-none-border::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 420px;
    height: 4px;
    background-color: #FDFDFD;
  }
  @media (max-width: 1365px) {
    .careers-pros-card-none {
      display: none;
    }
  }
  @media (max-width: 1365px) {
    .careers-pros-card-position {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  @media (max-width: 767px) {
    .careers-pros-card-position {
      position: relative;
    }
  }
  .careers-pros-card-wrapper {
    width: 250px;
    display: flex;
    margin: 70px auto 30px;
    justify-content: space-between;
  }
  @media (max-width: 1919px) {
    .careers-pros-card-wrapper {
      width: 190px;
      margin: 50px auto 30px;
    }
  }
  @media (max-width: 1365px) {
    .careers-pros-card-wrapper {
      margin: 50px auto 30px;
      width: 280px;
    }
  }
  @media (max-width: 767px) {
    .careers-pros-card-wrapper {
      width: 268px;
    }
  }
  .careers-pros-card_title {
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    width: 190px;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    background: linear-gradient(89.61deg, #28C0E2 -4.38%, #212121 106.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (max-width: 1919px) {
    .careers-pros-card_title {
      font-size: 16px;
      line-height: 140%;
      width: 150px;
    }
  }
  @media (max-width: 1365px) {
    .careers-pros-card_title {
      width: 220px;
    }
  }
  @media (max-width: 767px) {
    .careers-pros-card_title {
      width: 212px;
    }
  }
  .careers-pros-card_number,
  .careers-pros-card_text {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }
  @media (max-width: 1919px) {
    .careers-pros-card_number,
    .careers-pros-card_text {
      font-size: 16px;
      line-height: 140%;
    }
  }
  .careers-pros-card_text {
    width: 250px;
    margin: 0 auto;
  }
  @media (max-width: 1919px) {
    .careers-pros-card_text {
      width: 190px;
    }
  }
  @media (max-width: 1365px) {
    .careers-pros-card_text {
      width: 280px;
    }
  }
  @media (max-width: 767px) {
    .careers-pros-card_text {
      width: 268px;
    }
  }
  @media (max-width: 1919px) {
    .careers-pros-card-img {
      width: 250px;
      height: 250px;
    }
  }
  @media (max-width: 1365px) {
    .careers-pros-card-img {
      width: 340px;
      height: 200px;
    }
  }


  .careers-pros-card1-position{
    top: -4px;
  }
  .careers-pros-card2-position{
    top: -4px;
  }
  .careers-pros-card3-position{
    top: -4px;
  }
  @media (max-width: 1365px) {
    .careers-pros-card3-position{
      top: 998px;
    }
  }
  @media (max-width: 768px) {
    .careers-pros-card3-position{
      top: 0px;
    }
  }