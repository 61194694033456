  .accordion-vacancy-wrapper-content {
    position: relative;
    width: 860px;
    height: auto;
    overflow: hidden;
  }

  @media (max-width: 1919px) {
    .accordion-vacancy-wrapper-content {
      width: 700px;
    }
  }

  @media (max-width: 1365px) {
    .accordion-vacancy-wrapper-content {
      width: 430px;
    }
  }

  @media (max-width: 767px) {
    .accordion-vacancy-wrapper-content {
      width: 328px;
    }
  }


  .accordion-vacancy-buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 180px;
    border: none;
    background: none;
    outline: none;
    overflow: hidden;
    cursor: url(../../../img/careers/press_cursor.svg) 60 60, pointer;
    padding: 10px 0px 10px 28px;
    border-top: #303030 1px solid;
    border-left: #303030 1px solid;
    /* cursor: none;  */
  }

  @media (max-width: 1919px) {
    .accordion-vacancy-buttons {
      height: 165px;
    }
  }

  @media (max-width: 1365px) {
    .accordion-vacancy-buttons {
      height: 150px;
    }
  }

  @media (max-width: 767px) {
    .accordion-vacancy-buttons {
      height: 150px;
      padding-bottom: 25px;
    }
  }

  .accordion-vacancy-buttons:hover {
    color: white;
    background-color: #28C0E2;
    border: none;
  }



  .accordion-vacancy-buttons[aria-expanded='true']+.accordion-vacancy-content {
    opacity: 1;
    max-height: 2000px;
    transition: all 1s ease-in;
  }



  .accordion-vacancy-shortDescription {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    width: 100%;
    gap: 40px;
  }

  @media (max-width: 1919px) {
    .accordion-vacancy-shortDescription {
      gap: 40px;
    }
  }

  @media (max-width: 1365px) {
    .accordion-vacancy-shortDescription {
      gap: 25px;
    }
  }

  @media (max-width: 767px) {
    .accordion-vacancy-shortDescription {
      gap: 25px;
      flex-wrap: wrap;
    }
  }


  .accordion-shortDescription-item {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    font-family: 'Satoshi-Regular', 'sans-serif';
    color: #303030;
  }

  @media (max-width: 1919px) {
    .accordion-shortDescription-item {
      font-size: 16px;
      line-height: 22.4px;
    }
  }

  @media (max-width: 1365px) {
    .accordion-shortDescription-item {
      font-size: 16px;
      line-height: 22.4px;
    }
  }

  @media (max-width: 767px) {
    .accordion-shortDescription-item {
      font-size: 16px;
      line-height: 22.4px;
      height: 5px;
    }
  }



  .accordion-vacancy-title {
    font-size: 20px;
    font-family: 'Satoshi-bold', 'sans-serif';
    line-height: 28px;
    color: #303030;
    text-align: left;
  }

  @media (max-width: 1919px) {
    .accordion-vacancy-title {
      font-size: 16px;
      line-height: 22.4px;
    }
  }

  @media (max-width: 1365px) {
    .accordion-vacancy-title {
      font-size: 16px;
      line-height: 22.4px;
    }
  }

  @media (max-width: 767px) {
    .accordion-vacancy-title {
      font-size: 16px;
      line-height: 22.4px;
    }
  }






  .accordion-vacancy-content {
    max-height: 0;
    overflow: hidden;
    transition: all 1s ease;
    transition-delay: 0s;
    border-left: 2px solid #28C0E2;
    position: relative;
    left: -1px;
  }

  .accordion-vacancy-text {
    width: 860px;
    font-family: "Satoshi-Regular", sans-serif;
    padding: 15px 28px 15px 40px;
    border-top: #303030 1px solid;
    color: #303030;
    line-height: 140%;
  }

  .accordion-vacancy-text ul{
    list-style-type: disc
  }

  @media (max-width: 1919px) {
    .accordion-vacancy-text {
      width: 700px;
    }
  }

  @media (max-width: 1365px) {
    .accordion-vacancy-text {
      width: 430px;
    }
  }

  @media (max-width: 767px) {
    .accordion-vacancy-text {
      width: 328px;
      height: auto;
    }
  }




  .accordion-vacancy-cursor {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5000;
    transform: translateX(-50%) translateY(-50%);
    visibility: hidden;
    pointer-events: none;
    cursor: none;
  }

  .accordion-vacancy-cursor.active {
    visibility: visible;
    cursor: none;
  }

  .accordion-vacancy-cursor-start {
    animation-name: accordion-vacancy-cursor-start;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }

  @keyframes accordion-vacancy-cursor-start {
    0% {
      width: 0px;
      height: 0px;
    }

    100% {
      width: 150px;
      height: 150px;
    }
  }