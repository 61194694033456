.wrapper{
    background-color: #303030;
    margin-top: 0px;
}

.container {
    position: relative;
    margin-top: 110px;
    width: 1300px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

@media (max-width: 1919px) {
    .container {
        width: 1060px;
        height: 150px;
    }
}

@media (max-width: 1365px) {
    .container {
        width: 700px;
        height: 150px;
    }
}

@media (max-width: 767px) {
    .container {
        display: block;
        width: 328px;
        height: 390px;
        margin-top: -50px;
        padding-top: 50px;
    }
}



.title {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #FDFDFD;
}

@media (max-width: 1919px) {
    .title {
        font-size: 16px;
        line-height: 140%;
    }
}

@media (max-width: 767px) {
    .title {
        margin-top: 50px;
        margin-bottom: 10px;
    }
}



.list {
    display: flex;
    justify-content: space-between;
    width: 972px;
}

@media (max-width: 1919px) {
    .list {
        margin-top: 5px;
        width: 788px;
    }
}

@media (max-width: 1365px) {
    .list {
        width: 427px;
    }
}

@media (max-width: 767px) {
    .list {
        display: block;
        margin-top: 29px;
    }
}



.item__hover {
    position: relative;
    display: inline-block;
    color: #fdfdfd;
    overflow: hidden;
    background: linear-gradient(90deg, #0098EE -7.51%, #BD00FF 50%, #fdfdfd 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 0.5s ease;
    cursor: pointer;
}

.item__hover:hover {
    background-position: 0 100%;
}

.item a {
    display: block;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #FDFDFD;
    text-transform: uppercase;
    transition: 0.3s linear;
}

@media (max-width: 1919px) {
    .item a {
        font-size: 16px;
        line-height: 140%;
    }
}

@media (max-width: 767px) {
    .list {
        width: fit-content;
    }

    .item {
        width: fit-content;
    }

    .item a {
        margin-top: 20px;
        width: fit-content;
    }
}



.scrollUp {
    position: absolute;
    bottom: 255px;
    right: -100px;
    transform: rotateZ(90deg);
    line-height: 140%;
    padding-left: 50px;
    cursor: pointer;
    text-decoration: none;
    color: #fdfdfd;
    font-size: 20px;
}

@media (max-width: 1919px) {
    .scrollUp {
        font-size: 16px;
        bottom: 205px;
        right: -77px;
    }
}

@media (max-width: 1365px) {
    .scrollUp {
        font-size: 16px;
        right: -55px;
    }
}

@media (max-width: 767px) {
    .scrollUp {
        font-size: 16px;
        right: -25px;
        bottom: 245px;
        z-index: 1000;
    }
}

.scrollUp::before {
    position: absolute;
    content: "";
    height: 40px;
    width: 6px;
    left: 17px;
    top: 12px;
    background-image: url(../../img/arrow-white.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translateY(-50%) rotateZ(-90deg);
    transition: .3s;
}

.scrollUp:hover.scrollUp:before {
    left: 0px;
}