.team {
    width: 1300px;
    height: 862px;
    margin: 0 auto;
    margin-top: 200px;
  }
  @media (max-width: 1919px) {
    .team {
      width: 1060px;
      height: 755px;
      margin-top: 150px;
    }
  }
  @media (max-width: 1365px) {
    .team {
      position: relative;
      display: block;
      width: 100%;
      height: 597px;
      margin-top: 100px;
    }
  }
  @media (max-width: 767px) {
    .team {
      height: 567px;
      margin-top: 20px;
    }
  }
  .team-title {
    width: 1300px;
    height: 136px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    padding-right: 500px;
  }
  @media (max-width: 1919px) {
    .team-title {
      width: 700px;
      padding-right: 0;
    }
  }
  @media (max-width: 1365px) {
    .team-title {
      position: relative;
      display: flex;
      width: 700px;
      height: 98px;
      font-size: 36px;
      line-height: 49px;
      margin: 0 auto;
      margin-bottom: 50px;
      padding-right: 300px;
    }
  }
  @media (max-width: 767px) {
    .team-title {
      width: 328px;
      margin-bottom: 100px;
      padding: 0;
    }
  }
  .team-wrapper {
    display: flex;
    justify-content: space-between;
    scrollbar-width: none;
  }
  @media (max-width: 1365px) {
    .team-wrapper {
      justify-content: start;
      overflow: auto;
      margin: 0 auto;
      padding-left: 16px;
      max-width: 1415px;
    }
  }
  @media (max-width: 767px) {
    .team-wrapper {
      width: auto;
      margin-right: -80px;
    }
  }
  .team-wrapper::-webkit-scrollbar {
    display: none;
  }
  .team-card {
    width: 420px;
    height: 501px;
  }
  .team-card:nth-child(2n) {
    margin-top: 225px;
  }
  .team-card:nth-child(2n+1) {
    margin-top: 100px;
  }
  @media (max-width: 1919px) {
    .team-card {
      width: 340px;
      height: 449px;
    }
    .team-card:nth-child(2n) {
      margin-top: 170px;
    }
    .team-card:nth-child(2n+1) {
      margin-top: 70px;
    }
  }
  @media (max-width: 1365px) {
    .team-card {
      position: relative;
      width: 340px;
      height: 449px;
      margin-right: 20px;
    }
    .team-card:nth-child(1) {
      margin-top: 0;
      position: relative;
      left: 0px;
    }

    .team-card:nth-child(2n) {
      margin-top: 0;
      position: relative;
      left: 0px;
    }
    .team-card:nth-child(2n+1) {
      margin-top: 0;
    }
  }
  @media (max-width: 767px) {
    .team-card {
      width: 242px;
      height: 369px;
      margin-right: 17px;
    }

  }
  .team-flashlight-wrapper{
    width: 310px;
    height: 400px;
  }
  @media (max-width: 1919px) {
    .team-flashlight-wrapper {
      width: 250px;
      height: 350px;
    }
  }
  @media (max-width: 1365px) {
    .team-flashlight-wrapper {
      width: 340px;
      height: 380px;
    }
  }
  @media (max-width: 767px) {
    .team-flashlight-wrapper{
      width: 242px;
      height: 300px;
    }
  }

  .team-name {
    margin-top: 12px;
    margin-bottom: 7px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
  }
  @media (max-width: 1919px) {
    .team-name {
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 1365px) {
    .team-name {
      width: 340px;
    }
  }
  .team-position {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }
  @media (max-width: 1919px) {
    .team-position {
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 1365px) {
    .team-position {
      width: 340px;
    }
  }
  .team-link  {
      display: block;
      position: absolute;
      bottom: 30px;
      right: 0;
      font-family: "Satoshi-Bold", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      border-bottom: 2px solid #28C0E2;
      color: #28C0E2;
  }
  @media (max-width: 1365px) {
    .team-link-hr{
      margin-right: 17px;
    }
  }
  @media (min-width: 1366px) {
    .team-link{
      display: none;
    }
  }
 

  .company-photo {
    width: 1920px;
    height: 1000px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: -204px;
    background-color: #F3F3F3;
    cursor:  url(../../img/drag_coursor_black.svg) 60 60, pointer;
    padding: 1%;

  }
  @media (max-width: 1919px) {
    .company-photo {
      width: 100%;
      height: 700px; 
      margin-bottom: -155px;
      padding: 3%;
    }
  }
  @media (max-width: 1365px) {
    .company-photo {
      width: 100%;
      height: 600px; 
      margin-bottom: -100px;
    }
  }
  @media (max-width: 767px) {
    .company-photo {
      margin-top: 70px;
      width: 100%;
      height: 500px; 
      margin-bottom: -123px;
      pointer-events: none;
    }
  }






@media (max-width: 1365px){
  .collageTeam1920{
    display: none;
  }
}


@media (min-width: 1366px){
  .collageTeamTablet{
   display: none;
  }
}

.collageTeamTablet{
  height: 600px;
  width: 100%;
  margin-top: 100px;
  object-fit: cover;
}
@media (max-width: 767px){
  .collageTeamTablet{
   display: none;
  }
}

@media (min-width: 768px){
  .collageTeamMobile{
   display: none;
  }
}

.collageTeamMobile{
  height: 450px;
  width: 767px;
  margin-top: 70px;
  object-fit: cover;
}


