.ddt-information {
    background-color: #303030;
    height: 600px;
  }
  @media (max-width: 1919px) {
    .ddt-information {
      height: 500px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-information {
      height: 400px;
    }
  }
  @media (max-width: 767px) {
    .ddt-information {
      height: 545px;
    }
  }
  .ddt-information-wrapper {
    position: relative;
    display: flex;
    width: 1300px;
    height: 600px;
    margin: 0 auto;
  }
  @media (max-width: 1919px) {
    .ddt-information-wrapper {
      width: 1060px;
      height: 500px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-information-wrapper {
      width: 700px;
      height: 400px;
    }
  }
  @media (max-width: 767px) {
    .ddt-information-wrapper {
      display: block;
      width: 360px;
      height: 590px;
    }
  }
  .ddt-information_title {
    width: 650px;
    height: 406px;
    margin-top: 100px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 150px;
    line-height: 203px;
    color: #FDFDFD;
  }
  @media (max-width: 1919px) {
    .ddt-information_title {
      width: 609px;
      height: 298px;
      font-size: 110px;
      line-height: 149px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-information_title {
      width: 340px;
      height: 190px;
      font-size: 70px;
      line-height: 95px;
      margin-top: 104px;
    }
  }
  @media (max-width: 767px) {
    .ddt-information_title {
      width: 258px;
      height: 162px;
      font-size: 60px;
      line-height: 81px;
      margin: 0 15px;
      padding-top: 70px;
    }
  }
  .ddt-information_inner {
    margin-top: 200px;
    margin-left: 120px;
  }
  @media (max-width: 1919px) {
    .ddt-information_inner {
      margin-top: 180px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-information_inner {
      margin-left: 60px;
      margin-top: 95px;
    }
  }
  @media (max-width: 767px) {
    .ddt-information_inner {
      margin: 0;
      margin-top: 100px;
      margin-left: 13px;
    }
  }
  .ddt-information_text {
    width: 530px;
    height: auto;
    margin-bottom: 10px;
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #FDFDFD;
  }
  @media (max-width: 1919px) {
    .ddt-information_text {
      width: 430px;
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 1365px) {
    .ddt-information_text {
      width: 340px;
    }
  }
  @media (max-width: 767px) {
    .ddt-information_text {
      width: 328px;
    }
  }