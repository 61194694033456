.outsource-process {
    position: relative;
    width: 1300px;
    height: 486px;
    margin: 0 auto;
    margin-top: 187px;
  }
  @media (max-width: 1919px) {
    .outsource-process {
      width: 1060px;
      height: 456px;
      margin-top: 150px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-process {
      width: 700px;
      height: 380px;
      margin-top: 100px;
    }
  }
  @media (max-width: 767px) {
    .outsource-process {
      width: 328px;
      height: 690px;
      margin-top: 70px;
    }
  }
  .outsource-process-title {
    width: 480px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
  }
  @media (max-width: 1365px) {
    .outsource-process-title {
      width: 340px;
      height: 98px;
      font-size: 36px;
      line-height: 49px;
    }
  }
  @media (max-width: 767px) {
    .outsource-process-title {
      width: 328px;
      height: 98px;
    }
  }
  .outsource-process-wrapper {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1365px) {
    .outsource-process-wrapper {
      display: block;
    }
  }
  .outsource-process-card {
    margin-top: 100px;
    width: 420px;
    height: 250px;
  }
  @media (max-width: 1919px) {
    .outsource-process-card {
      margin-top: 70px;
      width: 340px;
      height: 250px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-process-card {
      margin: 0;
    }
  }
  @media (max-width: 1365px) {
    .outsource-process-card-tablet1 {
      position: absolute;
      left: 0;
      top: 150px;
      width: 340px;
      height: 202px;
    }
  }
  @media (max-width: 767px) {
    .outsource-process-card-tablet1 {
      width: 328px;
      height: 172px;
      top: 148px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-process-card-tablet2 {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 340px;
      height: 110px;
    }
  }
  @media (max-width: 767px) {
    .outsource-process-card-tablet2 {
      width: 328px;
      height: 110px;
      left: 0;
      top: 350px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-process-card-tablet3 {
      position: absolute;
      right: 0;
      top: 0;
      width: 340px;
      height: 250px;
    }
  }
  @media (max-width: 767px) {
    .outsource-process-card-tablet3 {
      width: 328px;
      height: 200px;
      left: 0;
      top: 490px;
    }
  }
  .outsource-process-subtitle {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 29px;
    cursor: pointer;
  }
  @media (max-width: 1365px) {
    .outsource-process-subtitle {
      font-size: 20px;
      line-height: 140%;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 767px) {
    .outsource-process-subtitle {
      margin-bottom: 22px;
    }
  }
  .outsource-process-subtitle-active {
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #28C0E2;
    margin-top: -1px;
  }
  @media (max-width: 1365px) {
    .outsource-process-subtitle-active {
      font-size: 20px;
      line-height: 140%;
    }
  }
  .outsource-process-span {
    margin-left: 20px;
  }
  .outsource-process-text {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }
  @media (max-width: 1919px) {
    .outsource-process-text {
      font-size: 16px;
      line-height: 140%;
    }
  }
  .outsource-process-img {
    width: 420px;
    height: 250px;
    border-radius: 12px;
    object-fit: cover;
  }
  @media (max-width: 1919px) {
    .outsource-process-img {
      width: 340px;
      height: 250px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-process-img {
      width: 340px;
      height: 250px;
    }
  }