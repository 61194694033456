.wrapper {
    width: 1300px;
    height: 732px;
    margin: 145px auto 200px;
  }
  @media (max-width: 1919px) {
    .wrapper{
      width: 1060px;
      height: 634px;
      margin-top: 95px;
      margin-bottom: 150px;
    }
  }
  @media (max-width: 1365px) {
    .wrapper {
      width: 700px;
      height: 632px;
      margin-top: 65px;
      margin-bottom: 100px;
    }
  }
  @media (max-width: 767px) {
    .wrapper {
      width: 328px;
      height: 1144px;
      margin-top: 35px;
      margin-bottom: 70px;
    }
  }
  .title {
    width: 420px;
    height: 136px;
    margin-bottom: 95px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
  }
  @media (max-width: 1919px) {
    .title {
      width: 430px;
      height: 136px;
      margin-bottom: 70px;
    }
  }
  @media (max-width: 1365px) {
    .title {
      width: 300px;
      height: 98px;
      font-size: 36px;
      line-height: 49px;
      margin-bottom: 50px;
    }
  }
  @media (max-width: 767px) {
    .title {
      margin-bottom: 0;
      width: 328px;
      height: 98px;
    }
  }
  .container{
    display: flex;
    justify-content: space-between;
    width: 1300px;
    height: 496px;
  }
  @media (max-width: 1919px) {
    .container{
      width: 1060px;
      height: 428px;
    }
  }
  @media (max-width: 1365px) {
    .container {
      width: 700px;
      height: 484px;
    }
  }
  @media (max-width: 767px) {
    .container {
      flex-wrap: wrap;
      width: 328px;
      height: 1020px;
      margin-top: 50px;
    }
  }