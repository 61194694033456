.ddt-pros {
    width: 1300px;
    height: 720px;
    margin: 0 auto;
    margin-top: 201px;
  }
  @media (max-width: 1919px) {
    .ddt-pros {
      width: 1060px;
      height: 620px;
      margin-top: 150px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-pros {
      margin-top: 100px;
      width: 700px;
      height: 940px;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros {
      width: 328px;
      height: 1506px;
      margin-top: 70px;
    }
  }
  .ddt-pros-title {
    display: flex;
    align-items: center;
    width: 420px;
    height: 350px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
  }
  @media (max-width: 1919px) {
    .ddt-pros-title {
      width: 340px;
      height: 300px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-pros-title {
      font-size: 36px;
      line-height: 49px;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros-title {
      display: none;
    }
  }
  .ddt-pros-title-mobile {
    display: none;
  }
  @media (max-width: 767px) {
    .ddt-pros-title-mobile {
      display: block;
      width: 310px;
      font-family: "Satoshi-Bold", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 49px;
    }
  }
  .ddt-pros-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    .ddt-pros-wrapper {
      display: block;
    }
  }
  .ddt-pros-card {
    position: relative;
    width: 420px;
    height: 350px;
    margin: 0;
    margin-left: 2px;
    margin-bottom: 20px;
    background-color: #FFFFFF;
    overflow: hidden;
  }
  .ddt-pros-card::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 420px;
    height: 2px;
    background-color: #303030;
  }
  @media (max-width: 1919px) {
    .ddt-pros-card {
      width: 340px;
      height: 300px;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros-card {
      margin-top: 50px;
      width: 328px;
      height: 300px;
      margin-bottom: -30px;
    }
  }
  .ddt-pros-card-last {
    position: relative;
    width: 860px;
    height: 350px;
    margin: 0;
    background-color: #FFFFFF;
    overflow: hidden;
  }
  .ddt-pros-card-last::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 860px;
    height: 2px;
    background-color: #303030;
  }
  @media (max-width: 1919px) {
    .ddt-pros-card-last {
      width: 700px;
      height: 300px;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros-card-last {
      width: 328px;
      height: 300px;
      margin-top: 50px;
    }
  }
  .ddt-pros-card-wrapper {
    width: 360px;
    margin: 70px auto 30px auto;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1919px) {
    .ddt-pros-card-wrapper {
      width: 278px;
      margin: 50px auto 30px auto;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros-card-wrapper {
      width: 268px;
    }
  }
  .ddt-pros-card-wrapper-last {
    width: 800px;
    margin: 70px auto 30px auto;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1919px) {
    .ddt-pros-card-wrapper-last {
      width: 640px;
      margin: 50px auto 30px auto;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros-card-wrapper-last {
      width: 268px;
    }
  }
  .ddt-pros-card_title {
    width: 280px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    background: linear-gradient(89.61deg, #28C0E2 -4.38%, #212121 106.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (max-width: 1919px) {
    .ddt-pros-card_title {
      width: 240px;
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros-card_title {
      width: 212px;
    }
  }
  .ddt-pros-card_number,
  .ddt-pros-card_text {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }
  @media (max-width: 1919px) {
    .ddt-pros-card_number,
    .ddt-pros-card_text {
      font-size: 16px;
      line-height: 140%;
    }
  }
  .ddt-pros-card_text {
    width: 360px;
    margin: 0 auto;
  }
  @media (max-width: 1919px) {
    .ddt-pros-card_text {
      width: 278px;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros-card_text {
      width: 268px;
    }
  }
  .ddt-pros-card_text-last {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    width: 720px;
    margin-left: 30px;
  }
  @media (max-width: 1919px) {
    .ddt-pros-card_text-last {
      width: 580px;
      margin-left: 30px;
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 767px) {
    .ddt-pros-card_text-last {
      width: 268px;
    }
  }