.outstaff-process {
  width: 1300px;
  height: 800px;
  margin: 0 auto;
  margin-top: 200px;
  display: flex;
}

@media (max-width: 1919px) {
  .outstaff-process {
    width: 1060px;
    height: 600px;
    margin-top: 150px;
  }
}

@media (max-width: 1365px) {
  .outstaff-process {
    width: 700px;
    height: auto;
    margin-top: 100px;
    display: block;
  }
}

@media (max-width: 767px) {
  .outstaff-process {
    margin-top: 68px;
    display: block;
    width: 328px;
    height: auto;
    margin-bottom: -30px;
  }
}

.outstaff-process-img {
  width: 530px;
  height: 800px;
  border-radius: 12px;
  background-color: #E5E5E5;
  object-fit: cover;
}

@media (max-width: 1919px) {
  .outstaff-process-img {
    width: 445px;
    height: 600px;
  }
}

@media (max-width: 1365px) {
  .outstaff-process-img {
    width: 700px;
    height: 400px;
    object-position: 50% 40%;
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .outstaff-process-img {
    width: 328px;
    height: 300px;
    margin-bottom: 0px;
  }
}

.outstaff-process-inform {
  width: 660px;
  height: 800px;
  margin-left: 130px;
}

@media (max-width: 1919px) {
  .outstaff-process-inform {
    width: 540px;
    height: 600px;
    margin-left: 110px;
  }
}

@media (max-width: 1365px) {
  .outstaff-process-inform {
    width: 340px;
    height: auto;
    margin-left: 0px;
    display: block;
  }
}

@media (max-width: 767px) {
  .outstaff-process-inform {
    display: block;
    width: 328px;
    height: auto;
    margin-top: 49px;
    border: none;
  }
}




.outstaff-process-inform_subtitle {
  font-family: 'Satoshi-Bold';
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
}

@media (max-width: 1919px) {
  .outstaff-process-inform_subtitle {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1365px) {
  .outstaff-process-inform_subtitle {
    display: none;
  }
}

@media (min-width: 1366px) {
  .outstaff-process-inform_subtitle_mobile {
    display: none;
  }
}

@media (max-width: 1365px) {
  .outstaff-process-inform_subtitle_mobile {
    font-family: 'Satoshi-Bold';
    width: 250px;
    font-size: 36px;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .outstaff-process-inform_subtitle_mobile {
    width: 300px;
    font-size: 36px;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 50px;
  }
}



.outstaff-process-step {
  width: 640px;
  position: relative;
  margin-top: 30px;
}


@media (max-width: 1919px) {
  .outstaff-process-step {
    width: 520px;
    margin-top: 25px;
  }
}

@media (max-width: 1365px) {
  .outstaff-process-step {
    width: 700px;
  }
}

@media (max-width: 767px) {
  .outstaff-process-step {
    width: 328px;
    margin-bottom: 29px;
  }
}



.outstaff-process-step_number {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {
  .outstaff-process-step_number {
    font-size: 16px;
    line-height: 130%;
  }
}

.outstaff-process-step_title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  margin: 15px 0;
  text-transform: uppercase;
}

@media (max-width: 1919px) {
  .outstaff-process-step_title {
    font-size: 16px;
    line-height: 22.4px;
    margin: 10px 0px;
  }
}

.outstaff-process-step_text {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {
  .outstaff-process-step_text {
    font-size: 16px;
    line-height: 21.5px;
  }
}

@media (max-width: 1365px) {
  .outstaff-process-step_text {
    width: 609px;
  }
}

@media (max-width: 767px) {
  .outstaff-process-step_text {
    width: 328px;
  }
}