.wrapper {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    cursor: none;
}

@media (max-width: 767px) {
    .wrapper {
        display: none;
    }
}

.img__front {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    cursor: none;
}

.img__back {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    -o-object-fit: cover;
    object-fit: cover;
    transition: 0.5s linear;
    cursor: none;
    visibility: hidden;
}

.loop__start {
    animation-name: loop__start;
    animation-duration: .2s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

@keyframes loop__start {
    0% {
        clip-path: circle(0px at var(--x) var(--y));
    }

    100% {
        clip-path: circle( var(--loopSize) at var(--x) var(--y));
    }
}

.img__back_active {
    z-index: 10;
    height: 100%;
    width: 100%;
    cursor: none;
    -o-object-fit: cover;
    object-fit: cover;
}



.cursor {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5000;
    width: var(--cursorWidth);
    height: var(--cursorHeight);
    transform: translateX(-50%) translateY(-50%);
    visibility: hidden;
    cursor: none;
    transition: .03s ease;
    -moz-transition: .03s ease;
}

.cursor_active {
    visibility: visible;
    cursor: none;
}

.cursor__start {
    animation-name: cursor__start;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

@keyframes cursor__start {
    0% {
        width: 0px;
        height: 0px;
    }

    100% {
        width: var(--cursorWidth);
        height: var(--cursorHeight);
    }
}


.img__mobile {
    display: none;
}

@media (max-width: 767px) {
    .img__mobile {
        display: block;
        width: 100%;
        height: 300px;
        -o-object-fit: cover;
        object-fit: cover;
    }
}