.cookies-wrapper {
    width: 100%;
    height: 72px;
    background-color: #303030;
    color: #FDFDFD;
    z-index: 10000;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    display: none;
}

.cookies-wrapper::after{
    content: '';
    height: 2px;
    width: 100%;
    background-color: #28C0E2;
    position: absolute;
    top: 0px;
    left: 0px;
}

@media (max-width: 1365px) {
    .cookies-wrapper {
        height: 69px;
    }
}

@media (max-width: 767px) {
    .cookies-wrapper {
        height: 164px;
        flex-direction: column;
        gap: 16px;
    }
}




.cookies-wrapper p {
    width: 1155px;
    font-size: 14px;
    line-height: 19.6px;
    text-align: center;
}

@media (max-width: 1919px) {
    .cookies-wrapper p {
        width: 880px;
        padding-right: 40px;
    }
}

@media (max-width: 1365px) {
    .cookies-wrapper p {
        width: 430px;
        text-align: left;
        font-size: 12px;
        line-height: 16.8px;
        padding-right: 0px;
    }
}

@media (max-width: 767px) {
    .cookies-wrapper p {
        text-align: center;
        width: 328px;
        font-size: 12px;
        line-height: 16.8px;
    }
}




.cookies-wrapper p br {
    display: none;
}

@media (max-width: 767px) {
    .cookies-wrapper p br {
        display: block;
    }
}




.cookies-wrapper a {
    color: #28C0E2;
}





.cookies-button {
    background-color: #28C0E2;
    padding: 8px 24px;
    border: none;
    border-radius: 35px;
    color: #303030;
    font-size: 14px;
    line-height: 140%;
    cursor: pointer;
}

@media (max-width: 1365px) {
    .cookies-button {
        font-size: 12px;
        line-height: 16.8px;
    }
}

@media (max-width: 767px) {
    .cookies-button {
        width: 200px;
        height: 33px;
    }
}


.cookies-button:nth-child(3){
    background-color: #ffffff;
    color: #303030;
}



