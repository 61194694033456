.container {
  display: block;
  width: 640px;
  height: 350px;
}

@media (max-width: 1919px) {
  .container {
    width: 520px;
    height: 300px;
  }
}

@media (max-width: 1365px) {
  .container {
    width: 340px;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 328px;
    height: 300px;
    margin-bottom: -30px;
  }
}

.img__wrapper{
  position: relative;
}

.img__hover{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 350px;
  background: linear-gradient(89.61deg, #28C0E2 -4.38%, #212121 106.96%);
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1919px) {
  .img__hover {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .img__hover {
    height: 290px;
    display: none;
  }
}

.img__hover_title{
  font-family: 'Satoshi-Regular';
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: white;
  opacity: 1;
}

@media (max-width: 1919px) {
  .img__hover_title {
    font-size: 24px;
    line-height: 32.4px;
  }
}

@media (max-width: 1365px) {
  .img__hover_title {
    font-size: 20px;
    line-height: 27px;
  }
}

.title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 15px 0;
}

@media (max-width: 1365px) {
  .title {
    font-size: 20px;
    line-height: 27px;
    margin: 10px 0;
  }
}

.text {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {
  .text {
    font-size: 16px;
    line-height: 140%;
  }
}