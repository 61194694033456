@media (max-width: 767px) {
    .ddt-photo-wrapper {
      position: relative;
      margin: 0 auto;
      margin-top: 70px;
      width: 100%;
      height: 250px;
    }
  }
  .ddt-photo {
    width: 1300px;
    height: 820px;
    margin: 0 auto;
    margin-top: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    scrollbar-width: none;

  }
  @media (max-width: 1919px) {
    .ddt-photo {
      margin-top: 150px;
      width: 1060px;
      height: 620px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-photo {
      margin-top: 100px;
      width: 700px;
      height: 891px;
    }
  }
  @media (max-width: 767px) {
    .ddt-photo {
      position: absolute;
      flex-wrap: nowrap;
      overflow-x: auto;
      margin: 0 auto;
      padding-left: 16px;
      width: 100%;
      height: 250px;
    }
  }
  .ddt-photo::-webkit-scrollbar {
    display: none;
  }
  .ddt-photo::-webkit-scrollbar {
    display: none;
  }
  .ddt-photo-img1 {
    width: 640px;
    height: 450px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 20px;
    background-color: #E5E5E5;
  }
  @media (max-width: 1919px) {
    .ddt-photo-img1 {
      width: 520px;
      height: 350px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-photo-img1 {
      width: 340px;
      height: 300px;
    }
  }
  @media (max-width: 767px) {
    .ddt-photo-img1 {
      width: 242px;
      height: 250px;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  .ddt-photo-img2 {
    width: 640px;
    height: 450px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 20px;
    background-color: #E5E5E5;
  }
  @media (max-width: 1919px) {
    .ddt-photo-img2 {
      width: 520px;
      height: 350px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-photo-img2 {
      width: 340px;
      height: 300px;
    }
  }
  @media (max-width: 767px) {
    .ddt-photo-img2 {
      width: 242px;
      height: 250px;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  .ddt-photo-img3 {
    width: 420px;
    height: 350px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 20px;
    background-color: #E5E5E5;
  }
  @media (max-width: 1919px) {
    .ddt-photo-img3 {
      width: 340px;
      height: 250px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-photo-img3 {
      width: 340px;
      height: 250px;
    }
  }
  @media (max-width: 767px) {
    .ddt-photo-img3 {
      width: 242px;
      height: 250px;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  .ddt-photo-img4 {
    width: 420px;
    height: 350px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 20px;
    background-color: #E5E5E5;
  }
  @media (max-width: 1919px) {
    .ddt-photo-img4 {
      width: 340px;
      height: 250px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-photo-img4 {
      width: 340px;
      height: 250px;
    }
  }
  @media (max-width: 767px) {
    .ddt-photo-img4 {
      width: 242px;
      height: 250px;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  .ddt-photo-img5 {
    width: 420px;
    height: 350px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 20px;
    background-color: #E5E5E5;
  }
  @media (max-width: 1919px) {
    .ddt-photo-img5 {
      width: 340px;
      height: 250px;
    }
  }
  @media (max-width: 1365px) {
    .ddt-photo-img5 {
      width: 700px;
      height: 300px;
    }
  }
  @media (max-width: 767px) {
    .ddt-photo-img5 {
      width: 242px;
      height: 250px;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }