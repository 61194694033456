.wrapper {
  width: 1300px;
  height: 856px;
  margin: 0 auto;
  margin-top: 200px;
}

@media (max-width: 1919px) {
  .wrapper {
    width: 1060px;
    height: 726px;
    margin-top: 150px;
  }
}

@media (max-width: 1365px) {
  .wrapper {
    width: 700px;
    height: 958px;
    margin-top: 100px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    width: 328px;
    height: 788px;
    margin-top: 70px;
  }
}

.title {
  width: 460px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  margin-bottom: 100px;
}

@media (max-width: 1919px) {
  .title {
    margin-bottom: 70px;
  }
}

@media (max-width: 1365px) {
  .title {
    width: 330px;
    margin-bottom: 50px;
    font-size: 36px;
    line-height: 49px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.block1 {
  margin-bottom: 20px;
  width: 1300px;
  height: 300px;
  background-image: url(../../img/service/outsource/outsource-pic1.webp);
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 31px;
  padding-left: 30px;
}

@media (max-width: 1919px) {
  .block1 {
    background-image: url(../../img/service/outsource/outsource-pic1-1366.webp);
    width: 1060px;
    height: 250px;
    padding-left: 20px;
  }
}

@media (max-width: 1365px) {
  .block1 {
    background-image: url(../../img/service/outsource/outsource-pic1-tablet.webp);
    background-size: 700px 250px;
    width: 700px;
    height: 250px;
  }
}

@media (max-width: 767px) {
  .block1 {
    background-image: url(../../img/service/outsource/outsource-pic1-mobile.webp);
    background-size: 328px 199px;
    margin-bottom: 20px;
    width: 328px;
    height: 199px;
    padding-left: 10px;
    gap: 21px;
  }
}

.block1__title {
  color: #fdfdfd;
  font-size: 24px;
  line-height: 32.4px;
  font-weight: 500;
  font-family: 'Satoshi-Regular';
  position: relative;
}

.block1__title::after {
  content: '';
  width: 448px;
  height: 1px;
  background-color: #28C0E2;
  position: absolute;
  left: 0px;
  bottom: -10px;
}

@media (max-width: 1919px) {
  .block1__title {
    font-size: 21px;
    line-height: 28px;
  }

  .block1__title::after {
    width: 400px;
  }
}

@media (max-width: 1365px) {
  .block1__title {
    max-width: 295px;
    font-size: 21px;
    line-height: 28px;
  }

  .block1__title::after {
    width: 295px;
  }
}

@media (max-width: 767px) {
  .block1__title {
    max-width: 190px;
    font-size: 15px;
    line-height: 20.25px;
  }

  .block1__title::after {
    width: 190px;
  }
}

.block1__text {
  max-width: 448px;
  color: #fdfdfd;
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
  font-family: 'Satoshi-Regular';
}

@media (max-width: 1919px) {
  .block1__text {
    max-width: 400px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .block1__text {
    max-width: 295px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 767px) {
  .block1__text {
    max-width: 180px;
    font-size: 14px;
    line-height: 19.6px;
  }
}

/* sdsd */

.block2 {
  width: 640px;
  height: 300px;
  background-image: url(../../img/service/outsource/outsource-pic2.webp);
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 31px;
  padding-left: 30px;
}

@media (max-width: 1919px) {
  .block2 {
    width: 520px;
    height: 250px;
    background-size: 520px 250px;
    background-image: url(../../img/service/outsource/outsource-pic2-1366.webp);
    padding-left: 20px;
  }
}

@media (max-width: 1365px) {
  .block2 {
    background-image: url(../../img/service/outsource/outsource-pic2-tablet.webp);
    background-size: 700px 250px;
    width: 700px;
    height: 250px;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .block2 {
    background-image: url(../../img/service/outsource/outsource-pic2-mobile.webp);
    background-size: 328px 199px;
    width: 328px;
    height: 199px;
    margin-bottom: 20px;
    padding-left: 10px;
    gap: 21px;
  }
}

.block2__title {
  color: #fdfdfd;
  font-size: 24px;
  line-height: 32.4px;
  font-weight: 500;
  font-family: 'Satoshi-Regular';
  position: relative;
}

.block2__title::after {
  content: '';
  width: 281px;
  height: 1px;
  background-color: #28C0E2;
  position: absolute;
  left: 0px;
  bottom: -10px;
}

@media (max-width: 1919px) {
  .block2__title {
    font-size: 21px;
    line-height: 28px;
  }

  .block2__title::after {
    width: 230px;
  }
}

@media (max-width: 1365px) {
  .block2__title {
    font-size: 21px;
    line-height: 28px;
  }

  .block2__title::after {
    width: 295px;
  }
}

@media (max-width: 767px) {
  .block2__title {
    font-size: 15px;
    line-height: 20.25px;
  }

  .block2__title::after {
    width: 190px;
  }
}

.block2__text {
  max-width: 280px;
  color: #fdfdfd;
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
  font-family: 'Satoshi-Regular';
}

@media (max-width: 1919px) {
  .block2__text {
    max-width: 230px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .block2__text {
    max-width: 295px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 767px) {
  .block2__text {
    max-width: 190px;
    font-size: 14px;
    line-height: 19.6px;
  }
}




.block3 {
  width: 640px;
  height: 300px;
  background-image: url(../../img/service/outsource/outsource-pic3.webp);
  background-repeat: no-repeat;
  background-position: right;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 31px;
  padding-left: 30px;
}

@media (max-width: 1919px) {
  .block3 {
    width: 520px;
    height: 250px;
    background-size: 520px 250px;
    background-image: url(../../img/service/outsource/outsource-pic3-1366.webp);
    padding-left: 20px;
  }
}

@media (max-width: 1365px) {
  .block3 {
    background-image: url(../../img/service/outsource/outsource-pic3-tablet.webp);
    background-size: 700px 250px;
    width: 700px;
    height: 250px;
    margin-left: 0px;
  }
}

@media (max-width: 767px) {
  .block3 {
    background-image: url(../../img/service/outsource/outsource-pic3-mobile.webp);
    background-size: 328px 199px;
    width: 328px;
    height: 199px;
    padding-left: 10px;
    gap: 21px;
  }
}