.information_list {
    width: 1300px;
    height: 150px;
    margin-top: -50px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
  
  @media (max-width: 1919px) {
    .information_list {
      grid-template-columns: 30% 25% 25% 25%;
      width: 1060px;
      height: 150px;
    }
  }
  
  @media (max-width: 1365px) {
    .information_list {
      grid-template-columns: 26% 25% 25% 25%;
      width: 700px;
      height: 130px;
      margin-top: -40px;
    }
  }
  
  @media (max-width: 767px) {
    .information_list {
      margin-top: 50px;
      width: 329px;
      height: 290px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  
.information_item {
    display: block;
    /*     width: 311px;
  */
    margin-right: 89px;
    border-left: 2px solid #28C0E2;
  }
  
  @media (max-width: 1919px) {
    .information_item {
      /*       width: 267px;
  */
      margin-right: 30px;
    }
  }
  
  @media (max-width: 1365px) {
    .information_item {
      /*       width: 180px;
  */
      margin-right: 46px;
    }
  }
  
  @media (max-width: 767px) {
    .information_item {
      width: 155px;
      height: 130px;
      margin: 0;
      margin-bottom: 29px;
    }
  
    .information_item:nth-child(2) {
      margin-left: 19px;
    }
  
    .information_item:nth-child(4) {
      margin-left: 19px;
    }
  }
  
  .information_item_subtitle {
    margin-left: 20px;
    margin-top: 13px;
    font-family: "Satoshi-Black", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 95px;
    color: #FDFDFD;
    -webkit-margin-left: 20px;
    -webkit-margin-top: 13px;
    -webkit-font-family: "Satoshi-Black", sans-serif;
    -webkit-font-style: normal;
    -webkit-font-weight: 900;
    -webkit-font-size: 70px;
    -webkit-line-height: 95px;
    -webkit-color: #FDFDFD;
  }
  
  @media (max-width: 1919px) {
    .information_item_subtitle {
      margin-top: 18px;
      -webkit-margin-top: 18px;
    }
  }
  
  @media (max-width: 1365px) {
    .information_item_subtitle {
      font-size: 36px;
      line-height: 49px;
      margin-top: 16px;
      margin-bottom: 5px;
      -webkit-font-size: 36px;
      -webkit-line-height: 49px;
      -webkit-margin-top: 16px;
      -webkit-margin-bottom: 5px;
    }
  }
  
  .information_item_text {
    margin-left: 20px;
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #FDFDFD;
    -webkit-margin-left: 20px;
    -webkit-font-family: "Satoshi-Regular", sans-serif;
    -webkit-font-style: normal;
    -webkit-font-weight: 400;
    -webkit-font-size: 20px;
    -webkit-line-height: 140%;
    -webkit-color: #FDFDFD;
  }
  
  @media (max-width: 1919px) {
    .information_item_text {
      font-size: 16px;
      line-height: 140%;
      -webkit-font-size: 16px;
      -webkit-line-height: 140%;
    }
  }
  
  @media (max-width: 1365px) {
    .information_item_text {
      font-size: 16px;
      line-height: 140%;
      -webkit-font-size: 16px;
      -webkit-line-height: 140%;
    }
  }