.wrapper{
    position: absolute;
    top: 0;
    left: -2.5%;
    background-color: #303030;
    width: 105%;
    height: 99%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FDFDFD;
  }
  @media (max-width: 1919px) {
    .wrapper{
      width: 105.5%;
    }
  }
  @media (max-width: 1365px) {
    .wrapper{
      width: 108%;
    }
  }
  @media (max-width: 767px) {
    .wrapper{
      width: 108%;
    }
  }

  .title{
    font-family: 'Satoshi-Bold', 'sans-serif';
    font-weight: 900;
    font-size: 50px;
    line-height: 70px;
  }

  @media (max-width: 767px) {
    .title{
      font-size: 36px;
      line-height: 50px;
    }
  }
  
  .text{
    font-family: 'Satoshi-Regular', 'sans-serif';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }
  @media (max-width: 767px) {
    .text{
      font-size: 16px;
    }
  }