.company1-process {
  width: 1300px;
  height: 1278px;
  margin: 0 auto;
  display: flex;
  justify-content:flex-start;
  margin-top: 200px;
  margin-bottom: 10px;
}

@media (max-width: 1919px) {
  .company1-process {
    height: 1040px;
    width: 1060px;
    margin-top: 150px;
    margin-bottom: 0px;
  }
}

@media (max-width: 1365px) {
  .company1-process {
    width: 700px;
    height: auto;
    margin-top: 100px;
    display: block;
    margin-bottom: -40px;
  }
}

@media (max-width: 767px) {
  .company1-process {
    display: block;
    width: 328px;
    height: auto;
    margin-top: 70px;
    margin-bottom: 50px;
  }
}

.company1-process-img {
  width: 530px;
  height: 800px;
  border-radius: 12px;
  background-color: #E5E5E5;
}

@media (max-width: 1919px) {
  .company1-process-img {
    width: 430px;
    height: 600px;
  }
}

@media (max-width: 1365px) {
  .company1-process-img {
    width: 700px;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .company1-process-img {
    width: 328px;
    height: 300px;
    object-fit: cover;
  }
}

.company1-process-sticky {
  position: sticky;
  top: 20px;
}



.company1-process-inform {
  width: 660px;
  height: 800px;
  padding-left: 130px;
}

@media (max-width: 1919px) {
  .company1-process-inform {
    width: 540px;
    height: auto;
    padding-left: 110px;
  }
}

@media (max-width: 1365px) {
  .company1-process-inform {
    width: 340px;
    height: auto;
    display: block;
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  .company1-process-inform {
    width: 328px;
    height: auto;
    margin-top: 49px;
    border: none;
  }
}


.company1-process-inform_title {
  width: 480px;
  margin-top: 2px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
}

@media (max-width: 1919px) {
  .company1-process-inform_title {
    width: 480px;
  }
}

@media (max-width: 1365px) {
  .company1-process-inform_title {
    width: 340px;
    font-size: 36px;
    line-height: 49px;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .company1-process-inform_title {
    width: 328px;
    margin-bottom: 40px;
  }
}







.company1-process-step {
  width: 660px;
  height: 171px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
}

.company1-process-step:last-child {
  border-bottom: none;
}

.company1-process-step div:nth-child(1) {
  width: 150px;
}

.company1-process-step div:nth-child(2) {
  width: 510px;
}



@media (max-width: 1919px) {
  .company1-process-step {
    width: 520px;
    height: 134px;
  }

  .company1-process-step:last-child {
    border-bottom: none;
  }
}

@media (max-width: 1365px) {
  .company1-process-step {
    width: 520px;
    height: 134px;
  }
}

@media (max-width: 767px) {
  .company1-process-step {
    width: 328px;
    height: auto;
    display: block;
  }

  .company1-process-step div:nth-child(1) {
    width: 150px;
    margin: 25px 0 15px 0;
  }

  .company1-process-step div:nth-child(2) {
    margin-bottom: 25px;
  }
  
}





.company1-process-step_number {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  position: relative;
  top: -5px;
}

@media (max-width: 1919px) {
  .company1-process-step_number {
    font-size: 16px;
  }
}

.company1-process-step_title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  position: relative;
  top: 9px;
}

@media (max-width: 1919px) {
  .company1-process-step_title {
    font-size: 16px;
    line-height: 140%;
    top: 5px;
  }
}

.company1-process-step_text {
  width: 489px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  position: relative;
  top: 5px;
}

@media (max-width: 1919px) {
  .company1-process-step_text {
    width: 398px;
    font-size: 16px;
    line-height: 138%;
  }
}

@media (max-width: 767px) {
  .company1-process-step_text {
    width: 328px;
  }
}




@media (min-width: 1366px) {
  .grid_wrapper {
    overflow: inherit;
  }
}

@media (max-width: 1365px) {
  .grid_wrapper {
    overflow: hidden;
  }
}