.careers-information {
    background-color: #303030;
  }
  .careers-information-wrapper {
    display: flex;
    justify-content: space-between;
    width: 1300px;
    height: 600px;
    margin: 0 auto;
  }
  @media (max-width: 1919px) {
    .careers-information-wrapper {
      width: 1060px;
      height: 500px;
    }
  }
  @media (max-width: 1365px) {
    .careers-information-wrapper {
      width: 700px;
      height: 400px;
    }
  }
  @media (max-width: 767px) {
    .careers-information-wrapper {
      display: block;
      width: 328px;
      height: 590px;
    }
  }
  .careers-information_title {
    width: 713px;
    height: 406px;
    margin-top: 100px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 150px;
    line-height: 203px;
    color: #FDFDFD;
  }
  @media (max-width: 1919px) {
    .careers-information_title {
      width: 609px;
      height: 298px;
      font-size: 110px;
      line-height: 149px;
    }
  }
  @media (max-width: 1365px) {
    .careers-information_title {
      width: 50%;
      height: 95px;
      font-size: 70px;
      line-height: 95px;
      margin: 0;
      padding: 104px 0 30px 0;
    }
  }
  @media (max-width: 767px) {
    .careers-information_title {
      width: 328px;
      font-size: 60px;
      line-height: 81px;
      padding-top: 50px;
    }
  }
  .careers-information_texts {
    margin-top: 165px;
  }
  @media (max-width: 1919px) {
    .careers-information_texts {
      margin-top: 145px;
    }
  }
  @media (max-width: 1365px) {
    .careers-information_texts {
      margin-top: 71px;
      width: 50%;
    }
  }
  @media (max-width: 767px) {
    .careers-information_texts {
      margin-top: 148px;
    }
  }
  .careers-information_text {
    width: 530px;
    height: auto;
    margin-bottom: 19px;
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #FDFDFD;
  }
  @media (max-width: 1919px) {
    .careers-information_text {
      margin-bottom: 15px;
      width: 430px;
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 1365px) {
    .careers-information_text {
      width: 340px;
    }
  }
  @media (max-width: 767px) {
    .careers-information_text {
      width: 328px;
    }
  }