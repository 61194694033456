.presentation {
  width: 1920px;
  height: 910px;
  margin: 0 auto;
  margin-top: 70px;
  position: relative;
}

@media (max-width: 1919px) {
  .presentation {
    width: 1060px;
    height: 615px;
    margin-top: 50px;
  }
}

@media (max-width: 1365px) {
  .presentation {
    width: 700px;
    height: 525px;
    margin-top: 51px;
  }
}

@media (max-width: 767px) {
  .presentation {
    margin-top: 0px;
    padding-top: 30px;
    width: 328px;
    height: 444px;
  }
}

.presentation-button {
  display: none;
}

@media (max-width: 1365px) {
  .presentation-button {
    display: block;
    position: absolute;
    right: 120px;
    bottom: 253px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: none;
    background-color: #28C0E2;
    cursor: pointer;
    font-size: 18px;
    line-height: 25.2px;
    font-family: 'Satoshi-Regular';
    box-shadow: 0px 0px 30px #28C0E2;
    font-weight: 400;
    color: #303030;
  }
}

@media (max-width: 767px) {
  .presentation-button {
    right: 0;
    bottom: 50px;
  }
}

.presentation-title-right {
  text-align: right;
  margin-right: 310px;
  margin-top: 8px;
  font-family: "Satoshi-Black", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 170px;
  line-height: 230px;
}

@media (max-width: 1919px) {
  .presentation-title-right {
    font-size: 130px;
    line-height: 176px;
    margin-right: 0;
  }
}

@media (max-width: 1365px) {
  .presentation-title-right {
    color: #fdfdfd;
    font-size: 100px;
    line-height: 135px;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .presentation-title-right {
    text-align: left;
    font-size: 50px;
    line-height: 68px;
    margin: 0;
  }
}

.presentation-title-left {
  font-family: "Satoshi-Black", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 170px;
  line-height: 230px;
  margin-top: 12px;
  margin-left: 310px;
}

@media (max-width: 1919px) {
  .presentation-title-left {
    font-size: 130px;
    line-height: 176px;
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 1365px) {
  .presentation-title-left {
    color: #fdfdfd;
    font-size: 100px;
    line-height: 135px;
  }
}

@media (max-width: 767px) {
  .presentation-title-left {
    font-size: 50px;
    line-height: 68px;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}









.presentation1920 {
  width: 100%;
  height: 100%;
}

@media (max-width: 1919px) {
  .presentation1920 {
    display: none;
  }
}



.presentation1366 {
  width: 100%;
  height: 100%;
}

@media (min-width: 1920px) {
  .presentation1366 {
    display: none;
  }
}

@media (max-width: 1365px) {
  .presentation1366 {
    display: none;
  }
}



@media (min-width: 1366px) {
  .presentationText {
    display: none;
  }
}

.presentationText {
  width: 100%;
  height: 100%;
}

@media (max-width: 1365px) {
  .presentationText {
    background-image: url(../../img/MainTablet.webp);
    background-position: center;
  }
}

@media (max-width: 767px) {
  .presentationText {
    background-image: url(../../img/MainMobile.webp);
  }
}