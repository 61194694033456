.subtitle {
  width: 1300px;
  margin: 150px auto 20px auto;
  text-align: left;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  color: #28C0E2;
}

@media (max-width: 1919px) {
  .subtitle {
    width: 1060px;
    margin: 100px auto 20px auto;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .subtitle {
    width: 700px;
    margin: 70px auto 20px auto;
  }
}

@media (max-width: 767px) {
  .subtitle {
    width: 328px;
    margin: 50px auto 20px auto;
  }
}



.title {
  width: 1300px;
  margin: 0 auto;
  margin-bottom: 150px;
  font-family: "Satoshi-Black", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 70px;
  line-height: 95px;
}

@media (max-width: 1919px) {
  .title {
    width: 1060px;
    margin-bottom: 100px;
  }
}

@media (max-width: 1365px) {
  .title {
    width: 700px;
    font-size: 55px;
    line-height: 74px;
    margin-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .title {
    width: 328px;
    font-size: 45px;
    line-height: 61px;
    margin-bottom: 50px;
  }
}

.flashlight__wrapper {
  height: 600px;
  width: 100%;
}

@media (max-width: 1919px) {
  .flashlight__wrapper {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .flashlight__wrapper {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .flashlight__wrapper {
    width: 100%;
    height: 300px;
  }
}