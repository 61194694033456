.portfolio-content {
    width: 1300px;
    height: 230px;
    margin: 70px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 1919px) {
    .portfolio-content {
      width: 1060px;
      height: 176px;
    }
  }
  @media (max-width: 1365px) {
    .portfolio-content {
      display: block;
      text-align: left;
      width: 700px;
      height: auto;
    }
  }
  @media (max-width: 767px) {
    .portfolio-content {
      width: 328px;
      margin: 30px auto;
    }
  }
  .portfolio-title {
    font-family: "Satoshi-Black", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 170px;
    line-height: 230px;
  }
  @media (max-width: 1919px) {
    .portfolio-title {
      font-size: 130px;
      line-height: 176px;
    }
  }
  @media (max-width: 1365px) {
    .portfolio-title {
      font-size: 100px;
      line-height: 135px;
    }
  }
  @media (max-width: 767px) {
    .portfolio-title {
      font-size: 50px;
      line-height: 68px;
    }
  }
  .portfolio-text {
    margin-top: 50px;
    width: 280px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-align: right;
    text-transform: uppercase;
  }
  @media (max-width: 1919px) {
    .portfolio-text {
      width: 220px;
      font-size: 16px;
      line-height: 140%;
      margin-top: 40px;
    }
  }
  @media (max-width: 1365px) {
    .portfolio-text {
      text-align: left;
      width: 310px;
      height: 44px;
      margin-top: 20px;
    }
  }
  @media (max-width: 767px) {
    .portfolio-text {
      width: 328px;
      height: 44px;
    }
  }