.industries {
  display: flex;
  height: 420px;
}

@media (max-width: 1919px) {
  .industries {
    height: 350px;
  }
}

@media (max-width: 767px) {
  .industries {
    height: auto;
  }
}

.industries-counter-wrapper {
  position: relative;
  font-family: 'Satoshi-Regular';
  font-size: 30px;
  font-weight: 700;
  line-height: 140%;
  top: -70px;
  left: 1202px;
  display: flex;
}

@media (max-width: 1919px) {
  .industries-counter-wrapper {
    top: -70px;
    left: 975px;
  }
}

@media (max-width: 1365px) {
  .industries-counter-wrapper {
    top: -70px;
    left: 605px;
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .industries-counter-wrapper {
    display: none;
  }
}

.industries-counter {
  color: #28C0E2;
  width: 45px;
}

@media (max-width: 1365px) {
  .industries-counter {
    width: 40px;
  }
}

.industries-counter-total {
  width: 45px;
  text-align: end;
}

@media (max-width: 1365px) {
  .industries-counter-total {
    width: 40px;
  }
}

.industries2 {
  margin: 0 auto;
  margin-top: 202px;
  width: 1300px;
  height: 420px;
  position: relative;
}

@media (max-width: 1919px) {
  .industries2 {
    margin-top: 150px;
    width: 1060px;
    height: 350px;
  }
}

@media (max-width: 1365px) {
  .industries2 {
    margin-top: 100px;
    width: 700px;
    height: 350px;
  }
}

@media (max-width: 767px) {
  .industries2 {
    display: block;
    width: 328px;
    height: auto;
    margin-top: 70px;
  }
}

.industries-content {
  width: 420px;
  position: relative;
  top: 0px;
  left: 0px;
  transition: .2s ease;
}

@media (max-width: 1919px) {
  .industries-content {
    width: 340px;
  }
}

@media (max-width: 1365px) {
  .industries-content {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .industries-content {
    width: 328px;
    height: auto;
  }
}

.industries-title {
  width: 420px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  margin-top: 0px;
  margin-bottom: 110px;
  position: relative;
  top: 0px;
  left: 0px;
  transition: .2s;
}

@media (max-width: 1919px) {
  .industries-title {
    font-size: 50px;
    line-height: 68px;
    margin-bottom: 60px;
  }
}

@media (max-width: 1365px) {
  .industries-title {
    width: 250px;
    font-size: 36px;
    line-height: 49px;
    margin-bottom: 115px;
  }
}

@media (max-width: 767px) {
  .industries-title {
    margin-bottom: 30px;
  }
}


.industries-text {
  width: 350px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  position: relative;
  top: 0px;
  left: 0px;
  transition: .3s ease;
}

@media (max-width: 1919px) {
  .industries-text {
    font-size: 16px;
    line-height: 140%;
    width: 280px;
  }
}

@media (max-width: 1365px) {
  .industries-text {
    width: 245px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 767px) {
  .industries-text {
    width: 328px;
    margin-bottom: 50px;
  }
}


.industries-content-animation {
  color: #fdfdfd;
  visibility: hidden;
}

@media (max-width: 1919px) {
  .industries-content-animation {}
}

.industries-wrapper {
  width: auto;

  position: absolute;
  top: 0;
  left: 0px;
  scrollbar-width: none;
  display: flex;
  align-items: flex-start;

}



@media (max-width: 1919px) {
  .industries-wrapper {
    width: auto;
  }
}

@media (max-width: 1365px) {
  .industries-wrapper {
    width: auto;
    margin-left: 0.5%;
  }
}

@media (max-width: 767px) {

  .industries-wrapper {
    display: none;
  }
}

.slider-services-main {
  left: -455px;
  width: 2640px;
}

@media (max-width: 1919px) {
  .slider-services-main{
    left: -380px;
    width: 1840px;
  }
}

@media (max-width: 1365px) {
  .slider-services-main {
    left: -380px;
    width: 1410px;
  }
}


.industries-card-info {
  position: relative;
  left: -20px;
}


@media (min-width: 768px) {
  .industries-wrapper-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .industries-wrapper-mobile {
    position: relative;
    display: block;
    left: 0;
    top: -1px;
    width: 328px;
  }
}

.industries-wrapper::-webkit-scrollbar {
  display: none;
}






@media (max-width: 1919px) {
  .industries-card-1366none {
    display: none;
  }
}

@media (max-width: 1365px) {
  .industries-card-768none {
    display: none;
  }
}