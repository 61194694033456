.outstaff-comparison {
    width: 1300px;
    height: 1025px;
    margin: 0 auto;
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media (max-width: 1919px) {
    .outstaff-comparison {
      width: 1060px;
      height: 895px;
      margin-top: 150px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-comparison {
      width: 700px;
      height: 1000px;
      margin-top: 70px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-comparison {
      width: 328px;
      height: 1357px;
    }
  }
  .outstaff-comparison-wrapper {
    position: relative;
    width: 640px;
    height: 473px;
  }
  @media (max-width: 1919px) {
    .outstaff-comparison-wrapper {
      width: 520px;
      height: 425px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-comparison-wrapper {
      width: 700px;
      height: 262px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-comparison-wrapper {
      width: 328px;
      height: 483px;
    }
  }
  .outstaff-comparison-title {
    width: 550px;
    margin-bottom: 30px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
  }
  @media (max-width: 1365px) {
    .outstaff-comparison-title {
      font-size: 36px;
      line-height: 49px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-comparison-title {
      width: 328px;
    }
  }
  .outstaff-comparison-text {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 15px;
  }
  @media (max-width: 1919px) {
    .outstaff-comparison-text {
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 767px) {
    .outstaff-comparison-text {
      width: 328px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-comparison-text-tablet1 {
      position: relative;
      top: 0px;
      left: 0;
      width: 340px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-comparison-text-tablet1 {
      top: -0px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-comparison-text-tablet2 {
      position: absolute;
      right: 0;
      bottom: -15px;
      width: 340px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-comparison-text-tablet2 {
      width: 328px;
    }
  }
  .outstaff-comparison-scheme {
    width: 455px;
    height: 362px;
    margin-top: 55px;
  }
  @media (max-width: 1919px) {
    .outstaff-comparison-scheme {
      width: 455px;
      height: 362px;
      margin-top: 31px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-comparison-scheme {
      width: 455px;
      height: 342px;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-comparison-scheme {
      width: 328px;
      height: 260px;
      margin-top: -20px;
    }
  }
  .outstaff-difference {
    margin-top: 100px;
    margin-left: 110px;
    width: 495px;
    height: 450px;
  }
  @media (max-width: 1919px) {
    .outstaff-difference {
      margin-top: 75px;
      margin-left: 90px;
      width: 430px;
      height: 400px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-difference {
      margin-top: 0px;
      margin-left: 0px;
      width: 350px;
      height: 298px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-difference {
      margin-top: -30px;
      width: 328px;
      height: 200px;
    }
  }
  .outstaff-difference1 {
    position: relative;
    margin-top: 100px;
    margin-right: 35px;
    width: 495px;
    height: 450px;
  }
  .outstaff-difference1::before {
    content: "";
    position: absolute;
    bottom: 225px;
    left: -341px;
    width: 450px;
    height: 1px;
    transform: rotate(90deg);
    background-color: #A0A0A0;
  }
  @media (max-width: 1919px) {
    .outstaff-difference1::before {
      width: 400px;
      bottom: 200px;
      left: -300px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-difference1::before {
      display: none;
  }
}
  @media (max-width: 1919px) {
    .outstaff-difference1 {
      margin: 0;
      margin-top: 75px;
      margin-left: 50px;
      width: 430px;
      height: 400px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-difference1 {
      margin-top: 0px;
      margin-left: 0px;
      padding-left: 10px;
      width: 350px;
      height: 300px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-difference1 {
      margin-top: -40px;
      padding-left: 0px;
      width: 328px;
      height: 250px;
    }
    .outstaff-difference1::before {
      display: none;
    }
  }
  .outstaff-difference-title {
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 28px 0 50px;
    margin-left: -70px;
  }
  @media (max-width: 1919px) {
    .outstaff-difference-title {
      margin-left: -90px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-difference-title {
      width: 700px;
      text-align: left;
      margin: 29px 35px 30px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-difference-title {
      width: 328px;
      font-size: 20px;
      line-height: 27px;
    }
  }
  .outstaff-difference-text {
    position: relative;
    width: 495px;
    margin-left: 35px;
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 20px;
  }
  .outstaff-difference-text::before {
    content: "";
    position: absolute;
    top: 13.5px;
    left: -32px;
    width: 20px;
    height: 1px;
    background-color: #A0A0A0;
  }
  @media (max-width: 1919px) {
    .outstaff-difference-text::before {
      top: 11px;
      left: -35px;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-difference-text::before {
      top:11px;
      left: -34px;
    }
  }
  @media (max-width: 1919px) {
    .outstaff-difference-text {
      width: 395px;
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 1365px) {
    .outstaff-difference-text {
      width: 575px;
    }
  }
  @media (max-width: 767px) {
    .outstaff-difference-text {
      width: 293px;
      margin-bottom: 18px;
    }
  }