.wrapper {
    position: relative;
    width: 1300px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  
  @media (max-width: 1919px) {
    .wrapper {
      width: 1060px;
    }
  }
  
  @media (max-width: 1365px) {
    .wrapper {
      width: 700px;
    }
  }
  
  @media (max-width: 767px) {
    .wrapper {
      width: 328px;
    }
  }



  .list {
    width: 307px;
    display: flex;
    justify-content: flex-start;
  }
  
  @media (max-width: 1919px) {
    .list {
      width: 265px;
    }
  }
  
  @media (max-width: 1365px) {
    .list {
      width: 241px;
    }
  }
  
  @media (max-width: 767px) {
    .list {
      width: 180px;
    }
  }

  

.item,
.year {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  transition: 1s;
}

@media (max-width: 1919px) {
  .item,
  .year {
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .item,
  .year {
    font-size: 14px;
    line-height: 140%;
  }
}

@media (max-width: 767px) {
  .item,
  .year {
    font-size: 14px;
    line-height: 140%;
  }
}

.item:last-child{
  margin-left: 20px;
}

.item a {
  display: block;
  position: relative;
  transition: 1s;
}

.item a:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #28C0E2;
  content: "";
  transition: width 0.3s ease-out;
}

.item a:hover:after,
.item a:focus:after {
  width: 100%;
}

.item a:hover {
  color: #28C0E2;
}
