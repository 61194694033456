.wrapper {
    width: 1300px;
    height: auto;
    margin: 0 auto;
}

@media (max-width: 1919px) {
    .wrapper {
        width: 1060px;
    }
}

@media (max-width: 1365px) {
    .wrapper {
        width: 698px;
    }
}

@media (max-width: 767px) {
    .wrapper {
        width: 328px;
    }
}


.title {
    font-family: 'Satoshi-Bold';
    font-weight: 700;
    font-size: 50px;
    line-height: 67.5px;
    margin-top: 200px;
    color: #303030;
}

@media (max-width: 1919px) {
    .title {
        margin-top: 100px;
        font-size: 36px;
        line-height: 48.6px;
    }
}

@media (max-width: 1365px) {
    .title {
        margin-top: 100px;
        font-size: 36px;
        line-height: 48.6px;
    }
}

@media (max-width: 767px) {
    .title {
        margin-top: 70px;
    }
}

.text {
    font-family: 'Satoshi-Regular';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-top: 30px;
    width: 590px;
    color: #303030;
}

@media (max-width: 1919px) {
    .text {
        font-size: 16px;
        line-height: 22.4px;
        margin-top: 30px;
        width: 520px;
    }
}

@media (max-width: 1365px) {
    .text {
        font-size: 16px;
        line-height: 22.4px;
        margin-top: 30px;
        width: 520px;
    }
}

@media (max-width: 767px) {
    .text {
        margin-top: 20px;
        width: 328px;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.block {
    width: 530px;
}

@media (max-width: 1919px) {
    .block {
        width: 430px;
    }
}

@media (max-width: 1365px) {
    .block {
        width: 430px;
    }
}

@media (max-width: 767px) {
    .block {
        width: 328px;
    }
}

.block>hr {
    margin-top: 17px;
}

@media (max-width:1919px) {
    .block>hr {
        margin-top: 15px;
    }
}


.subtitle {
    font-family: 'Satoshi-Bold';
    font-size: 36px;
    line-height: 48.6px;
    font-weight: 700;
    margin-top: 70px;
}

@media (max-width: 1919px) {
    .subtitle {
        font-size: 24px;
        line-height: 32.4px;
        margin-top: 85px;
    }
}

@media (max-width: 1365px) {
    .subtitle {
        font-size: 24px;
        line-height: 32.4px;
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .subtitle {
        font-size: 24px;
        line-height: 32.4px;
        margin-top: 50px;
    }
}

.block__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.technology {
    width: 184px;
}
.technology__design {
    margin-top: 15px;
}
.technology__specialists {
    margin-top: 15px;
}

@media (max-width: 1919px) {
    .technology {
        width: 174px;
    }
}

@media (max-width: 767px) {
    .technology {
        width: 276px;
    }
}



.technology__title {
    font-family: 'Satoshi-Bold';
    font-size: 24px;
    font-weight: 700;
    line-height: 32.4px;
    margin-top: 30px;
}

@media (max-width: 1919px) {
    .technology__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;
    }
}




.technology__container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 15px;
    gap: 40px;
}
.technology__container_design{
    width: 300px;
}


@media (max-width: 1919px) {
    .technology__container {
        gap: 30px;
    }
}
