.portfolio-case {
  width: 1300px;
  height: 100%;
  margin: 0 auto;
  margin-bottom: -126px;
  display: flex;
  border-top: 1px solid #A0A0A0;
}

@media (max-width: 1919px) {
  .portfolio-case {
    width: 1060px;
    height: 100%;
  }
}

@media (max-width: 1365px) {
  .portfolio-case {
    width: 700px;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .portfolio-case {
    display: block;
    width: 328px;
    height: 100%;
    margin-top: 50px;
  }
}

.portfolio-case-desc {
  position: sticky;
  top: 20px;
  width: 445px;
  height: 960px;
  border-right: 1px solid #A0A0A0;
}

@media (max-width: 1919px) {
  .portfolio-case-desc {
    width: 340px;
    height: 860px;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-desc {
    width: 249px;
    height: 760px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-desc {
    width: 328px;
    height: auto;
    border: none;
    position: inherit;
    display: flex;
    flex-direction: column;
  }
}

.portfolio-case-desc-title {
  margin-top: 100px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
}

@media (max-width: 1365px) {
  .portfolio-case-desc-title {
    font-size: 36px;
    line-height: 49px;
    margin-top: 70px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-desc-title {
    margin-top: 50px;
  }
}

.portfolio-case-desc-text {
  width: 309px;
  height: 140px;
  margin-top: 30px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {
  .portfolio-case-desc-text {
    width: 300px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-desc-text {
    margin-top: 20px;
    width: 229px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-desc-text {
    width: 328px;
  }
}

.portfolio-case-desc-button {
  position: absolute;
  left: 0;
  top: 390px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: none;
  background-color: #28C0E2;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  transition: 500ms ease;
  box-shadow: 0px 0px 30px #28C0E2;
}

.portfolio-case-desc-button:hover {
  transform: scale(1.15);
}


@media (max-width: 1919px) {
  .portfolio-case-desc-button {
    margin-top: -55px;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-desc-button {
    margin-top: -90px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-desc-button {
    position: inherit;
    margin-top: 0px;
  }
}



.portfolio-case-preview {
  /* overflow: hidden; */
  width: 859px;
  height: 100%;
  padding-bottom: 280px;
  position: relative;
  cursor: none;
}

@media (max-width: 1919px) {
  .portfolio-case-preview {
    width: 719px;
    height: 100%;
    position: relative;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-preview {
    width: 455px;
    height: 100%;
    padding-bottom: 230px;

  }
}

@media (max-width: 767px) {
  .portfolio-case-preview {
    overflow: visible;
    width: 328px;
    height: auto;
  }
}

.portfolio-case-wrapper {
  display: block;
  margin-top: 100px;
  cursor: none;
}



@media (max-width: 1365px) {
  .portfolio-case-wrapper {
    margin-top: 70px;
    margin-left: 3px;
    height: 580px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-wrapper {
    margin-bottom: 0px;
  }
}

.portfolio-case-iner {
  position: relative;
  width: 746px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  margin-left: 109px;
  border-bottom: 2px solid #303030;
}

@media (max-width: 1919px) {
  .portfolio-case-iner {
    width: 610px;
    height: 60px;
    justify-content: space-between;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-iner {
    width: 430px;
    height: 49px;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-iner {
    width: 328px;
    height: 45px;
    margin-left: -4px;
    margin-top: -2px;
  }
}

.portfolio-case-mini-wrapper {
  display: flex;
  justify-content: space-between;
  width: 420px;
}

@media (max-width: 1919px) {
  .portfolio-case-mini-wrapper {
    width: 340px;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-mini-wrapper {
    width: 100%;
  }
}

.portfolio-case-year {
  margin-top: 18px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {
  .portfolio-case-year {
    font-size: 16px;
    line-height: 140%;
    margin-top: 23px;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-year {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-year {
    margin-top: 8px;
  }
}

.portfolio-case-title {
  margin-top: 1px;
  margin-left: -13px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
}

@media (max-width: 1919px) {
  .portfolio-case-title {
    /* margin-left: 35px; */
  }
}

@media (max-width: 1365px) {
  .portfolio-case-title {
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
    margin-left: 200px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-title {
    margin-top: -2px;
    margin-left: 98px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-title-saqure {
    margin-left: 111px;
  }
}

.portfolio-case-industries {
  margin-top: 18px;
  width: 217px;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {
  .portfolio-case-industries {
    width: 186px;
    margin-top: 22px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-industries {
    left: 273px;
    position: absolute;
    right: 0;
    bottom: -374px;
    width: 156px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-industries {
    left: 171px;
    font-size: 14px;
    gap: 10px;
  }
}

.portfolio-case-industries p:nth-child(even){
  color: #28C0E2;
}

.portfolio-case-information {
  position: relative;
  margin-top: 50px;
  width: 750px;
  height: 600px;
  margin-left: 109px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 1919px) {
  .portfolio-case-information {
    width: 610px;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-information {
    display: block;
    width: 430px;
    margin-top: 30px;
    margin-left: 20px;
    height: 600px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-information {
    width: 328px;
    margin-left: -4px;
  }
}

.portfolio-case-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 600px;
  border-radius: 12px;
}

@media (max-width: 1919px) {
  .portfolio-case-banner {
    width: 340px;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-banner {
    margin-bottom: 59px;
    width: 430px;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-banner {
    /* width: 328px;
    height: 300px; */
    display: none;
  }
}
@media (min-width: 768px) {
  .portfolio-case-banner-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .portfolio-case-banner-mobile {
    width: 328px;
    height: 300px;
    object-fit: cover;
    margin-bottom: 59px;
    border-radius: 12px;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-banner-logo {
    width: 400px;
    height: 105px;
  }
}

.portfolio-case-text {
  margin-bottom: 18px;
  width: 310px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {
  .portfolio-case-text {
    width: 250px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-text {
    margin-bottom: 14px;
    width: 430px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-text {
    width: 328px;
  }
}

.portfolio-case-name {
  position: absolute;
  right: 4px;
  bottom: 0;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #28C0E2;
  text-transform: uppercase;
}

@media (max-width: 1919px) {
  .portfolio-case-name {
    right: 1px;
    bottom: 0px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .portfolio-case-name {
    position: absolute;
    left: 0;
    bottom: 258px;
  }
}

@media (max-width: 767px) {
  .portfolio-case-name {
    font-size: 14px;
  }
}


.portfolio-case-cursor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100; 
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  display: none;
  cursor: none;
}

.portfolio-case-cursor.active {
  display: block;
  cursor: none;
}

@media (max-width: 1280px) {
  .portfolio-case-cursor {
    display: none;
  }
  .portfolio-case-cursor.active {
    display: none;
    cursor: pointer;
  }
}


.portfolio-case-cursor-start{
  animation-name: portfolio-case-cursor-start;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes portfolio-case-cursor-start{
  0%{
    width: 0px;
    height: 0px;
  }

  100%{
    width: 150px;
    height: 150px;
  }
}




@media (min-width: 1366px) {
  .grid_wrapper {
    overflow: inherit;
  }
}

@media (max-width: 1365px) {
  .grid_wrapper {
    overflow: hidden;
  }
}