.outsource-work {
    display: flex;
    justify-content: space-between;
    width: 1300px;
    height: 433px;
    margin: 0 auto;
    margin-top: 200px;
  }
  @media (max-width: 1919px) {
    .outsource-work {
      width: 1060px;
      height: 379px;
      margin-top: 150px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-work {
      display: block;
      width: 700px;
      height: 711px;
      margin-top: 100px;
    }
  }
  @media (max-width: 767px) {
    .outsource-work {
      width: 328px;
      height: 581px;
      margin-top: 70px;
    }
  }
  .outsource-work-content {
    width: 530px;
    height: 433px;
  }
  @media (max-width: 1919px) {
    .outsource-work-content {
      width: 430px;
      height: 379px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-work-content {
      width: 520px;
      height: 319px;
    }
  }
  @media (max-width: 767px) {
    .outsource-work-content {
      width: 328px;
      height: 385px;
    }
  }
  .outsource-work-title {
    width: 470px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    margin-bottom: 30px;
  }
  @media (max-width: 1365px) {
    .outsource-work-title {
      width: 340px;
      font-size: 36px;
      line-height: 49px;
    }
  }
  .outsource-work-text {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 15px;
  }
  @media (max-width: 1919px) {
    .outsource-work-text {
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 767px) {
    .outsource-work-text {
      margin-top: 13px;
    }
  }
  .outsource-communication {
    position: relative;
    width: 680px;
    height: 342px;
    margin-top: 85px;
  }
  @media (max-width: 1919px) {
    .outsource-communication {
      width: 600px;
      height: 282px;
      margin-top: 70px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-communication {
      width: 700px;
      height: 342px;
      margin-top: 50px;
    }
  }
  @media (max-width: 767px) {
    .outsource-communication {
      width: 328px;
      height: auto;
      margin-top: 30px;
    }
  }