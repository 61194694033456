.container {
  width: 100%;
  max-width: 1360px;
  padding: 0 10px;
  margin: 0 auto;
}

@media (max-width: 1919px) {
  .container {
    width: 100%;
    max-width: 1300px;
    padding: 0 10px;
    margin: 0 auto;
    padding-left: 25px;
  }
}

.wrapper {
  /*   position: relative;
 */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 1300px;
}

@media (max-width: 1919px) {
  .wrapper {
    /*     position: relative;
 */
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 1060px;
  }
}

.navWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 70px;
  width: 70px;
}

.navigationList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  column-gap: 30px;
  padding-right: 15px;
}

.navigationList li {
  line-height: 24.3px;
}

@media (max-width: 1919px) {
  .navigationList li {
    line-height: 19px;
  }
}

.navigationList li>a {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24.3px;
  text-transform: uppercase;
  color: #303030;
  transition: ease 0.5s;
}

.navigationList li>a:hover {
  color: #28c0e2;
}

.navigationList li.active>a {
  color: #28c0e2;
  cursor: default;
}

.nav_item a {
  display: block;

  transition: 1s;
  position: relative;
}

.nav_item a:after {
  display: block;
  position: absolute;
  left: 0;
  /*изменить на right:0;, чтобы изменить направление подчёркивания */
  width: 0;
  /*задаём длинну линии до наведения курсора*/
  height: 2px;
  /*задаём ширину линии*/
  background-color: #28C0E2;
  /*задаём цвет линии*/
  content: "";
  transition: width 0.3s ease-out;
  /*задаём время анимации*/
}

.nav_item a:hover:after

/* ,
.nav_item a:focus:after  */
  {
  width: 100%;

}

/*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/



@media (max-width: 1919px) {
  .nav_item a {
    font-size: 14px;
    line-height: 19px;
  }
}

@media (max-width: 1365px) {
  .nav_item a {
    width: 149px;
    margin-top: 40px;
    margin-left: 335px;
    font-family: "Satoshi-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #FDFDFD;
    text-align: left;
  }

  .nav_item a:after {
    display: none;
  }

  .nav_item a:hover:after,
  .nav_item a:focus:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .nav_item a {
    margin-top: 20px;
    margin-left: 250px;
    margin-bottom: 25px;
    height: auto;
    font-size: 16px;
    line-height: 140%;
  }
}

.contactBtn {
  transition: ease 0.5s;
}

.contactBtn:hover {
  transform: scale(1.1);
}

.scrollDown {
  position: absolute;
  top: 138px;
  right: -115px;
  transform: rotateZ(90deg);
  line-height: 140%;
  padding-right: 50px;
  cursor: pointer;
  color: #303030;
  font-size: 20px;
  text-decoration: none;
  z-index: 1000;
}

@media (max-width: 1919px) {
  .scrollDown {
    top: 130px;
    right: 35px;
    font-size: 16px;
  }
}

@media (max-width: 1365px) {
  .scrollDown {
    display: none;
  }
}

.scrollDown::after {
  position: absolute;
  content: "";
  height: 40px;
  width: 6px;
  right: 17px;
  top: 50%;
  background-image: url(../../img/header/arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: translateY(-50%) rotateZ(-90deg);
  transition: .3s;
}

.scrollDown:hover.scrollDown:after {
  /* animation: scrollAnim;
  animation-duration: 1s; */
  right: 0px;
}

.trigger:hover .secondLayer {
  display: flex;
}

.trigger span {
  display: block;
  transition: 1s;
  position: relative;
  font-size: 18px;
}

@media (max-width: 1919px) {
  .trigger span {
    font-size: 14px;
  }
}

li>span>a:hover {
  color: #28C0E2;
}

.trigger span:after {
  display: block;
  position: absolute;
  left: 0;
  /*изменить на right:0;, чтобы изменить направление подчёркивания */
  width: 0;
  /*задаём длинну линии до наведения курсора*/
  height: 2px;
  /*задаём ширину линии*/
  background-color: #28C0E2;
  /*задаём цвет линии*/
  content: "";
  transition: width 0.3s ease-out;
  /*задаём время анимации*/
}

@media (max-width: 1919px) {
  .trigger span:after {
    bottom: -2px;
  }
}

.trigger span:hover:after {
  width: 100%;
}


.secondLayer {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100vw;
  padding-top: 40px;
  z-index: 1999;
  flex-direction: column;
  align-items: center;
  /*   background: linear-gradient(0deg, rgba(253,253,253,1) 0%, rgba(253,253,253,1) 65%, rgba(0,212,255,0) 100%);
 */
}

@media (max-width: 1919px) {
  .secondLayer {
    display: none;
    position: absolute;
    top: 60px;
    width: 100.5%;
    padding-top: 25px;
    z-index: 1999;
  }
}

.secondLayer:after {
  content: '';
  width: 100%;
  height: 190px;
  margin-top: -190px;
  background-color: #FDFDFD;
  -webkit-box-shadow: 0px 50px 30px 0px rgba(206, 206, 206, 0.4);
  box-shadow: 0px 50px 30px 0px rgba(206, 206, 206, 0.4);
  /*   box-shadow: 0px 15px 25px rgba(206, 206, 206, 0.7); */
}

/* .secondLayer:before {
  content: '';
  width: 100%;
  position: absolute;
  top: 24px;
  height: 25px;
  z-index: 6000;
 background-color: #FDFDFD;
 background-color: orange;
}  */

.divider{
  border: none;
  border-bottom: 1px solid rgba(206, 206, 206, 0.5);
  z-index: 10000;
  width: 100vw;
  position: relative;
  top: 0px;
}
@media (max-width: 1919px) {
  .divider {
    top: 13px;
  }
}

.secondLayerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  height: 200px;
  max-width: 1300px;
  background-color: #FDFDFD;
  position: relative;
  left: -5px;
  z-index: 1999;
}

@media (max-width: 1919px) {
  .secondLayerWrapper {
    left: 0px;
  }
}

.headerCard {
  position: relative;
  width: 420px;
  height: 150px;
  text-decoration: none;
  color: #303030;
  z-index: 10;

}

@media (max-width: 1919px) {
  .headerCard {
    width: 340px;
    height: 140px;
  }
}

.headerCard>div {

  row-gap: 10px;
  background-color: #fdfdfd;
  height: inherit;
  width: inherit;
  padding: 10px 15px;
}

.headerCard::after {
  position: absolute;
  content: "";
  top: -2px;
  left: -2px;
  width: 2px;
  height: 154px;
  background-color: #28c0e2;
  z-index: -1;
  transition: ease 0.3s;
}

@media (max-width: 1919px) {
  .headerCard::after {
    height: 144px;
  }
}

.headerCard:hover.headerCard::after {
  width: 424px;
}

@media (max-width: 1919px) {
  .headerCard:hover.headerCard::after {
    width: 344px;
  }
}

.cardTitle {
  font-family: 'Satoshi-Bold', 'sans-serif';
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}

@media (max-width: 1919px) {
  .cardTitle {
    font-family: 'Satoshi-Bold', 'sans-serif';
    font-size: 16px;
    line-height: 22.4px;
    text-transform: uppercase;
  }
}

.cardText {
  font-weight: 400;
  font-size: 18px;
  line-height: 25.5px;
  margin-top: 12px;

}

@media (max-width: 1919px) {
  .cardText {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    margin-top: 12px;

  }
}

@keyframes scrollAnim {
  0% {
    right: 17px;
  }

  25% {
    right: 7px;
  }

  75% {
    right: 7px;
  }

  100% {
    right: 17px;
  }
}

@media (max-width: 1919px) {
  .navigationList li>a {
    font-size: 14px;
    line-height: 19px;
  }

}

.mobileWrapper {
  display: none;
}

/* ------------------------- tablet menu ----------------------------*/
@media (max-width: 1365px) {
  .wrapper {
    display: none;
  }

  .mobileWrapper {
    position: relative;
    display: block;
  }

  .mobileTop {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-right: 65px;
    height: 100px;
    width: 700px;
  }


  .mobileHeaderContainer {
    width: 700px;
    margin: 0 auto;
    position: relative;
  }

  .mobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }



  .logo {
    height: 70px;
    width: 70px;
  }

  .contactBtnMenuList {
    position: absolute;
    top: 24px;
    right: 65px;
   
  }


  .mobileMenuList {
    padding-top: 70px;
    padding-bottom: 220px;
  }

  .mobileMenuList ul {
    list-style: none;
    color: #fdfdfd;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    width: fit-content;
  }

  .mobileMenuList li {
    width: fit-content;
  }


  .mobileMenuList li+li {
    margin-top: 40px;
    cursor: pointer;
    width: fit-content;
  }


  .mobileMenuList li>a {
    text-decoration: none;
    color: #fdfdfd;
    text-align: right;
    width: fit-content;
  }

  .mobileLinks {
    height: 240px;
    border-top: 1px solid #fdfdfd;
  }

  .mobileLinksWrapper {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 313px;
    column-gap: 55px;
    row-gap: 30px;
  }

  .mobileLinksWrapper a {
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    text-transform: uppercase;
    color: #fdfdfd;
  }

  .burgerMenu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 30px;
    height: 36px;
    cursor: pointer;
    z-index: 10000;
  }

  .burgerMenu::after {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    background: #303030;
    top: 11px;
    right: 0;
    transition: ease 0.5s;
  }

  .burgerMenu::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    background: #303030;
    top: 23px;
    right: 0;
    transition: ease 0.5s;
  }

  .burgerMenu.active::after {
    background: #fdfdfd;
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg);
  }

  .burgerMenu.active::before {
    width: 30px;
    background: #fdfdfd;
    top: 50%;
    transform: translateY(-50%) rotateZ(-45deg);
  }

  .mobileMenu {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #303030;
    top: 0;
    right: -100vw;
    z-index: 9999;
    transition: ease 0.5s;
  }

  .mobileMenu.active {
    right: 0;
  }
}

.secondList ul {
  display: none;
  padding-left: 35px;
  padding-top: 0px;
}

.secondList.active ul {
  display: block;
  width: fit-content;
}

.secondList.active ul li {
  font-size: 22px;
  margin: 0px;
  line-height: 20px;
  z-index: 1000;
  padding: 15px 0 15px 0;
  width: fit-content;
}

.secondList.active ul li:first-child {
  margin-top: 20px;
}

.secondList.active ul li:last-child {
  margin-bottom: -20px;
}



/* ------------------------- mobile menu ----------------------------*/

@media (max-width: 767px) {
  .wrapper {
    display: none;
  }

  .mobileWrapper {
    position: relative;
    display: block;
  }

  .mobileTop {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 360px;
    padding-left: 16px;
  }

  .mobileHeaderContainer {
    width: 360px;
    margin: 0 auto;
    position: relative;
  }

  .logo {
    height: 50px;
    width: 50px;
  }

  .contactBtn {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 30px;
    right: 68px;
  }

  .mobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 60px;
    height: 100px;
    padding-left: 16px;
  }

  .contactBtnMenuList {
    position: absolute;
    top: 30px;
    right: 68px;
    width: 36px;
    height: 36px;
  }

  .mobileMenuList {
    padding-top: 20px;
    padding-bottom: 220px;
    padding-left: 15px;
  }

  .mobileMenuList ul {
    list-style: none;
    color: #fdfdfd;
    font-weight: 500;
    font-size: 16px;
    line-height: 21.6px;
    text-transform: uppercase;
    width: fit-content;
  }

  .mobileMenuList li+li {
    margin-top: 25px;
    width: fit-content;
  }

  .mobileMenuList li>a {
    text-decoration: none;
    color: #fdfdfd;
    text-align: right;
    width: fit-content;
  }

  .burgerMenu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
    width: 30px;
    height: 36px;
    cursor: pointer;
    z-index: 9999;
  }

  .burgerMenu::after {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    background: #303030;
    top: 11px;
    right: 0;
    transition: ease 0.5s;
  }

  .burgerMenu::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    background: #303030;
    top: 23px;
    right: 0;
    transition: ease 0.5s;
  }

  .burgerMenu.active::after {
    background: #fdfdfd;
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg);
  }

  .burgerMenu.active::before {
    width: 30px;
    background: #fdfdfd;
    top: 50%;
    transform: translateY(-50%) rotateZ(-45deg);
  }

  .mobileMenu {
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    background-color: #303030;
    top: 0;
    right: -100vw;
    z-index: 1000;
    transition: ease 0.5s;
  }

  .mobileMenu.active {
    right: 0;

  }

  .secondList ul {
    display: none;
    padding-left: 35px;
    padding-top: 0px;
  }

  .secondList.active ul {
    display: block;
    width: fit-content;
  }

  .secondList.active ul li {
    font-size: 16px;
    margin: 0px;
    line-height: 18.9px;
    z-index: 1000;
    padding: 10px 0 10px 0;
    width: fit-content;
  }

  .secondList.active ul li:first-child {
    margin-top: 15px;
  }

  .secondList.active ul li:last-child {
    margin-bottom: -5px;
  }


  .mobileLinks {
    display: none
  }

  .mobileLinksWrapper {
    display: none
  }
}



.bodyOverflowHidden{
  overflow: hidden;
}



.burgerMenu__white {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 30px;
  height: 36px;
  cursor: pointer;
  z-index: 10000;
}

.burgerMenu__white::after {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background: #fdfdfd;
  top: 11px;
  right: 0;
  transition: ease 0.5s;
}

.burgerMenu__white::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  background: #fdfdfd;
  top: 23px;
  right: 0;
  transition: ease 0.5s;
}

.burgerMenu__white.active::after {
  top: 50%;
  transform: translateY(-50%) rotateZ(45deg);
}

.burgerMenu__white.active::before {
  width: 30px;
  top: 50%;
  transform: translateY(-50%) rotateZ(-45deg);
}

.mobileWrapper__black{
  background: #1e1e1e;
}