.accordion-faq {
  width: 1300px;
  height: auto;
  margin: 200px auto 0px;
  overflow: hidden;
  background-color: #fdfdfd;
}
@media (max-width: 1919px) {
  .accordion-faq {
    width: 1060px;
    margin-top: 150px;
  }
}
@media (max-width: 1365px) {
  .accordion-faq {
    margin-top: 100px;
    width: 700px;
  }
}
@media (max-width: 767px) {
  .accordion-faq {
    margin-top: 70px;
    width: 328px;
  }
}
.accordion-faq-wrapper {
  width: 1080px;
  height: auto;
}
@media (max-width: 1919px) {
  .accordion-faq-wrapper {
    width: 880px;
  }
}
@media (max-width: 1365px) {
  .accordion-faq-wrapper {
    width: 700px;
  }
}
@media (max-width: 767px) {
  .accordion-faq-wrapper {
    width: 328px;
  }
}
.accordion-faq-title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  margin-bottom: 100px;
}
@media (max-width: 1919px) {
  .accordion-faq-title {
    margin-bottom: 70px;
  }
}
@media (max-width: 1365px) {
  .accordion-faq-title {
    margin-bottom: 50px;
    font-size: 36px;
    line-height: 49px;
  }
}
.accordion-faq-content {
  position: relative;
  width: 1080px;
  height: auto;
  border-top: 2px solid #303030;
  margin-bottom: 49px;
  background-color: #ffffff;
}
@media (max-width: 1919px) {
  .accordion-faq-content {
    width: 880px;
  }
}
@media (max-width: 1365px) {
  .accordion-faq-content {
    width: 700px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .accordion-faq-content {
    width: 328px;
    margin-bottom: 30px;
  }
}
.accordion-faq-content:after {
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
  /*изменить на right:0;, чтобы изменить направление подчёркивания */
  width: 0;
  /*задаём длинну линии до наведения курсора*/
  height: 2px;
  /*задаём ширину линии*/
  background-color: #28C0E2;
  /*задаём цвет линии*/
  content: "";
  transition: width 1s ease-out;
  /*задаём время анимации*/
}
.accordion-faq-content:hover:after,
.accordion-faq-content:focus:after {
  width: 100%;
  /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
}
.accordion-faq-content:hover .accordion-faq-subtitle {
  transition: 1s;
  color: #28C0E2;
}
.accordion-faq-content:hover .accordion-faq-subtitle1 {
  transition: 1s;
  color: #28C0E2;
}
.accordion-faq-content:hover .accordion-faq-subtitle2 {
  transition: 1s;
  color: #28C0E2;
}
.accordion-faq-content:hover .accordion-line {
  transition: 1s;
  background-color: #28C0E2;
}
.accordion-faq-content-svg {
  position: absolute;
  right: 30px;
  top: 30px;
}
@media (max-width: 767px) {
  .accordion-faq-content-svg {
    right: 13px;
    top: 31px;
  }
}
.accordion-faq-subtitle,
.accordion-faq-subtitle1,
.accordion-faq-subtitle2,
.accordion-faq-subtitle3 {
  display: flex;
  align-items: center;
  width: 1080px;
  height: 70px;
  margin-bottom: 50px;
  padding-left: 30px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}
@media (max-width: 1919px) {
  .accordion-faq-subtitle,
  .accordion-faq-subtitle1,
  .accordion-faq-subtitle2,
  .accordion-faq-subtitle3 {
    width: 880px;
    height: 70px;
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 1365px) {
  .accordion-faq-subtitle,
  .accordion-faq-subtitle1,
  .accordion-faq-subtitle2,
  .accordion-faq-subtitle3 {
    margin-bottom: 28px;
    padding-left: 20px;
    width: 620px;
  }
}
@media (max-width: 767px) {
  .accordion-faq-subtitle,
  .accordion-faq-subtitle1,
  .accordion-faq-subtitle2,
  .accordion-faq-subtitle3 {
    width: 280px;
    height: 120px;
    margin-bottom: 0;
    padding-left: 10px;
  }
}
@media (max-width: 1365px) {
  .accordion-faq-subtitle2 {
    margin-top: 8px;
    margin-bottom: 28px;
    padding-left: 20px;
    width: 620px;
  }
}
@media (max-width: 767px) {
  .accordion-faq-subtitle2 {
    width: 280px;
    margin-top: 0;
    padding-left: 10px;
    margin-bottom: 0;
  }
}
.accordion-line {
  display: block;
  position: absolute;
  z-index: 10;
  width: 11px;
  height: 1px;
  background-color: #303030;
  transition: 1s;
}
.accordion-line-1 {
  top: 35px;
  right: 28px;
  transform: rotate(-45deg);
  

}
@media (max-width: 767px) {
  .accordion-line-1 {
    right: 12px;
  }
}
.accordion-line-2 {
  top: 35px;
  right: 36px;
  transform: rotate(45deg);
}
@media (max-width: 767px) {
  .accordion-line-2 {
    right: 20px;
  }
}
.accordion-arrow-blue .accordion-line-1 {
  transform: rotate(45deg);
  background-color: #28C0E2;
}
.accordion-arrow-blue .accordion-line-2 {
  transform: rotate(-45deg);
  background-color: #28C0E2;
}
.buttons {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  border: none;
  background: none;
  outline: none;
  background-color: #ffffff;
  color: #303030;
}
.buttons:hover {
  color: #28C0E2;
}
.buttons:hover .new-faq-arrow{
  stroke: #28C0E2;
}
@media (max-width: 767px) {
  .buttons {
    height: 120px;
  }
}

.new-faq-arrow{
  position: relative;
  stroke: #303030;
}
@media (max-width: 767px) {
  .new-faq-arrow {
    width: 50px;
    position: relative;
    top: 0px;
    left: 10px;
  }
}
.rotate-180-cw {
  -webkit-animation: rotate-180-cw 0.3s ease both;
          animation: rotate-180-cw 0.3s ease both;
}
@-webkit-keyframes rotate-180-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-stroke: #28C0E2;
            stroke: #28C0E2;
  }
}
@keyframes rotate-180-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-stroke: #28C0E2;
            stroke: #28C0E2;
  }
}

.rotate-180-cw-return {
  -webkit-animation: rotate-180-cw-return 0.3s ease both;
          animation: rotate-180-cw-return 0.3s ease both;
}
@-webkit-keyframes rotate-180-cw-return {
  0% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes rotate-180-cw-return {
  0% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}



.accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 1080px;
  height: 70px;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  cursor: pointer;
}
@media (max-width: 1919px) {
  .accordion-title {
    width: 100%;
    height: 70px;
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 1365px) {
  .accordion-title {
    margin-bottom: 28px;
    padding-left: 20px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .accordion-title {
    width: 100%;
    height: 120px;
    margin-bottom: 0;
    padding-left: 20px;
  }
}
.buttons[aria-expanded='true'] {
  display: block;
}
.buttons[aria-expanded='true'] + .accordion-content {
  opacity: 1;
  max-height: 210px;
  transition: all 0.5s ease;
}
.buttons[aria-expanded='true'] + .accordion-content::after {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 1080px;
  height: 2px;
  background-color: #28C0E2;
}
@media (max-width: 1919px) {
  .buttons[aria-expanded='true'] + .accordion-content {
    width: 880px;
  }
  .buttons[aria-expanded='true'] + .accordion-content::after {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 880px;
    height: 2px;
    background-color: #28C0E2;
  }
}
@media (max-width: 1365px) {
  .buttons[aria-expanded='true'] + .accordion-content {
    max-height: 330px;
  }
}
@media (max-width: 767px) {

  .buttons[aria-expanded='true'] + .accordion-content {
    max-height: 300px;
    padding-bottom: 30px;
    transition: all 0.25s ease-in;
  }
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}




.accordion-faq-inner,
.accordion-faq-inner1,
.accordion-faq-inner2,
.accordion-faq-inner3 {
  display: none;
}
.faq-opened,
.faq-opened1,
.faq-opened2,
.faq-opened3 {
  display: block;
  width: 1080px;
  margin-top: -20px;
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.faq-opened::after,
.faq-opened1::after,
.faq-opened2::after,
.faq-opened3::after {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 1080px;
  height: 2px;
  background-color: #28C0E2;
}
@media (max-width: 1919px) {
  .faq-opened,
  .faq-opened1,
  .faq-opened2,
  .faq-opened3 {
    width: 880px;
    margin-top: -29px;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
  .faq-opened::after,
  .faq-opened1::after,
  .faq-opened2::after,
  .faq-opened3::after {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 880px;
    height: 2px;
    background-color: #28C0E2;
  }
}
@media (max-width: 1365px) {
  .faq-opened,
  .faq-opened1,
  .faq-opened2,
  .faq-opened3 {
    width: 700px;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .faq-opened::after,
  .faq-opened1::after,
  .faq-opened2::after,
  .faq-opened3::after {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 700px;
    height: 2px;
    background-color: #28C0E2;
  }
}
@media (max-width: 767px) {
  .faq-opened,
  .faq-opened1,
  .faq-opened2,
  .faq-opened3 {
    display: block;
    margin-top: -60px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    width: 328px;
    height: auto;
  }
  .faq-opened::after,
  .faq-opened1::after,
  .faq-opened2::after,
  .faq-opened3::after {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 329px;
    height: 2px;
    background-color: #28C0E2;
  }
}
.accordion-class,
.accordion-class1,
.accordion-class2,
.accordion-class3 {
  color: #28C0E2;
}
.accordion-faq-text {
  width: 910px;
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 16px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}
@media (max-width: 1919px) {
  .accordion-faq-text {
    width: 800px;
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 1365px) {
  .accordion-faq-text {
    width: 650px;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 14px;
  }
}
@media (max-width: 767px) {
  .accordion-faq-text {
    padding: 0 20px 0 20px;
    margin-left: 0px;
    margin-top: -3px;
    margin-bottom: 12px;
    width: 320px;
  }
}
.accordion-faq-item {
  margin-left: 65px;
  width: 1000px;
  list-style-type: disc;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}
@media (max-width: 1919px) {
  .accordion-faq-item {
    width: 790px;
    margin-left: 58px;
    font-size: 16px;
    line-height: 138%;
  }
}
@media (max-width: 1365px) {
  .accordion-faq-item {
    margin-left: 40px;
    width: 600px;
  }
}
@media (max-width: 767px) {
  .accordion-faq-item {
    line-height: 138%;
    width: 277px;
    margin-left: 35px;
    font-size: 16px;
  }
}
.accordion-faq-item-bold {
  font-family: "Satoshi-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
}
@media (max-width: 1919px) {
  .accordion-faq-item-bold {
    font-size: 16px;
    line-height: 140%;
  }
}
.accordion-faq-item-last {
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .accordion-faq-item-last {
    margin-bottom: -45px;
  }
}


.accordion-faq-list{
padding-bottom: 30px;
}
