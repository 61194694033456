.wrapper {
    width: 100%;
    height: 488px;
    background-color: #303030;
    display: flex;
    flex-direction: column;
    align-items: center;

}

@media (max-width: 1919px) {
    .wrapper {
        height: 488px;
    }
}

@media (max-width: 1365px) {
    .wrapper {
        height: 369px;
    }
}

@media (max-width: 767px) {
    .wrapper {
        height: 578px;
    }
}

.title {
    font-family: 'Satoshi-Bold';
    color: #fdfdfd;
    font-size: 50px;
    line-height: 67.5px;
    font-weight: 700;
    margin-top: 100px;
}

@media (max-width: 1919px) {
    .title {
        font-size: 50px;
    }
}

@media (max-width: 1365px) {
    .title {
        font-size: 36px;
        line-height: 48.6px;
        margin-top: 70px;
    }
}

@media (max-width: 767px) {
    .title {
        font-size: 36px;
        line-height: 48.6px;
        margin-top: 70px;
        width: 268px;
        text-align: center;
    }
}

.title>span {
    color: #28C0E2;
}

.informationList {
    margin-top: 120px;
}

@media (max-width: 1919px) {
    .informationList {
        margin-top: 120px;

    }
}

@media (max-width: 1365px) {
    .informationList {
        margin-top: 90px;
    }
}

@media (max-width: 767px) {
    .informationList {
        margin-top: 0px;
    }
}