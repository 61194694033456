.portfolio-wrapper {
  overflow: hidden;
  /* width: 1300px; */
  width: 100vw;
  height: 1120px;
  margin: 0 auto;
  margin-top: 175px;
  margin-bottom: -95px;
/*   cursor: none;
 */  position: relative;
}

@media (max-width: 1919px) {
  .portfolio-wrapper {
    height: 870px;
    margin-top: 120px;
    margin-bottom: -90px;

  }
}
@media (max-width: 1365px) {
  .portfolio-wrapper {
    height: 750px;
    margin-bottom: -20px;
  }
}
@media (max-width: 767px) {
  .portfolio-wrapper {
    width: 328px;
    height: auto;
    margin-top: -20px;
    margin-bottom: 0px;
  }
}


.portfolio {
  /* width: 1300px; */
  width: 100vw;
  height: 850px;
  margin: 0 auto;
  margin-top: 200px;
  display: flex;
  align-items: flex-start;
  cursor: none;
  position: relative; 
  padding: 25px 0px;
}

@media (max-width: 1919px) {
  .portfolio {
    margin-top: 155px;
/*     width: 1060px;
 */    height: 650px;
 width: 100vw;
  }
}
@media (max-width: 1365px) {
  .portfolio {
    margin-top: 50px;
    /* width: 700px; */
  }
}
@media (max-width: 767px) {
  .portfolio {
    display: block;
    position: relative;
    width: 328px;
    height: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .portfolio::before {
    content: "";
    position: absolute;
    bottom: -33px;
    width: 328px;
    height: 1px;
    background-color: #A0A0A0;
  }
}
.portfolio-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 530px;
  height: 800px;
  margin-bottom: 30px;
  
}
@media (max-width: 1919px) {
  .portfolio-img {
    width: 430px;
    height: 600px;
  
  }
}
@media (max-width: 1365px) {
  .portfolio-img {
    width: 340px;
    height: 600px;
  }
}
@media (max-width: 767px) {
  .portfolio-img {
    width: 328px;
    height: 300px;
    margin-right: 0;
    border-radius: 10px;
  }
}
@media (max-width: 1365px) {
  .portfolio-img-logo {
    margin-top: 20px;
    margin-left: 20px;
    height: 92px;
  }
}
@media (max-width: 767px) {
  .portfolio-img-logo {
    margin: 0;
    height: 92px;
  }
}
.portfolio-img-startup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 530px;
  height: 800px;
  margin-right: 130px;
  border-radius: 13px;
  background-color: #1B1A1A;
}
@media (max-width: 1919px) {
  .portfolio-img-startup {
    width: 430px;
    height: 600px;
    margin-right: 110px;
  }
}
@media (max-width: 1365px) {
  .portfolio-img-startup {
    width: 340px;
    height: 600px;
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .portfolio-img-startup {
    width: 328px;
    height: 300px;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .portfolio-img-logo-startup {
    height: 92px;
  }
}
.portfolio-card {
  display: flex;
  align-items: center;
  width: 530px;
  height: 100%;
  margin-bottom: 0px;
}
@media (max-width: 1919px) {
  .portfolio-card {
    width: 430px;
    height: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 1365px) {
  .portfolio-card {
    width: 360px;
    height: 100%;
    margin-bottom: 0px;
    margin-left: 20px;
  }
}
@media (max-width: 1365px) {
  .portfolio-card-wrapper {
    width: 360px;
  
  }
}
@media (max-width: 767px) {
  .portfolio-card {
    display: block;
    width: 328px;
    height: auto;
    margin-left: 0px;
  }
}

.portfolio-card-wrapper{
  animation-name:  card-text-animation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes card-text-animation{
  0%{
   transform: translateY(-900px);
   opacity: 0;
  }

  100%{
   transform: translateY(0px);
   opacity: 1;
  }
}

.portfolio-card_title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  color: #28C0E2;
}
@media (max-width: 1919px) {
  .portfolio-card_title {
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 1365px) {
  .portfolio-card_title {
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 767px) {
  .portfolio-card_title {
    width: 328px;
    margin-top: 30px;
  }
}
.portfolio-card_subtitle {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  margin: 10px 0;
}
@media (max-width: 1365px) {
  .portfolio-card_subtitle {
    font-size: 36px;
    line-height: 49px;
  }
}
.portfolio-card_desc {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
}
@media (max-width: 1919px) {
  .portfolio-card_desc {
    font-size: 16px;
    line-height: 140%;
    margin-top: 12px;
  }
}

.portfolio-card_desc span:nth-child(even){
  color: #28C0E2;
}

.portfolio-card_text {
  margin: 25px 0 100px;
}
@media (max-width: 1919px) {
  .portfolio-card_text {
    margin: 25px 0 70px;
  }
}
@media (max-width: 1365px) {
  .portfolio-card_text {
    margin: 22px 0 65px;
  }
}
@media (max-width: 767px) {
  .portfolio-card_text {
    width: 328px;
  }
}
.portfolio-card_text,
.portfolio-card_comment {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}
@media (max-width: 1919px) {
  .portfolio-card_text,
  .portfolio-card_comment {
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 1365px) {
  .portfolio-card_text,
  .portfolio-card_comment {
    width: auto;
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 767px) {
  .portfolio-card_text,
  .portfolio-card_comment {
    width: 328px;
  }
}
.portfolio-card_name {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-top: 20px;
}
@media (max-width: 1919px) {
  .portfolio-card_name {
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 1365px) {
  .portfolio-card_name {
    font-size: 16px;
    line-height: 140%;
  }
}
.portfolio-card-name2 {
  font-family: "Satoshi-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin-top: 20px;
}

.portfolio-slider {
  height: 830px;
  position: relative;
  cursor: none;
}
@media (max-width: 1919px) {
  .portfolio-slider {

    height: 600px;
  }
}
@media (max-width: 1365px) {
  .portfolio-slider {
    height: 600px;
  }
}



.portfolio-slide {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1190px;
  height: 830px;
  margin-right: 120px;
  border-radius: 12px;
  cursor: none;

}
@media (max-width: 1919px) {
  .portfolio-slide {
    width: 970px;
    height: 600px;
  }
}
@media (max-width: 1365px) {
  .portfolio-slide {
    width: 600px;
    height: 600px;
  }
}
@media (max-width: 767px) {
  .portfolio-slide {
    overflow: visible;
    display: block;
    width: 328px;
    height: 1500px;
    margin-top: 49px;
    border: none;
  }
}


.portfolio-process-card-wrapper{
  display: flex;
  height: 100%;
  align-items: center;
}


@media (max-width: 767px) {
  .portfolio-desktop{
      display: none;
  }
}
.portfolio-mobile{
  display: none;
}
@media (max-width: 767px) {
  .portfolio-mobile{
      display: block;
  }
}



.portfolio-cursor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; 
  transform: translateX(-50%) translateY(-50%);
  display: none;
  pointer-events: none;
  transition: .5s;
  cursor: none;
}

@media (max-width: 1280px) {
  .portfolio-cursor {
   display: none;
  }
}

.portfolio-cursor.active {
  display: block;
  cursor: none;
}

.portfolio-cursor-start{
  animation-name: portfolio-cursor-start;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes portfolio-cursor-start{
  0%{
    width: 0px;
    height: 0px;
  }

  100%{
    width: 150px;
    height: 150px;
  }
}


