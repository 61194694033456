.wrapper {
    position: relative;
    background-color: #303030;
}

.wrapper::before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #A0A0A0;
}
@media (max-width: 767px) {
    .wrapper {
    }
}



.container {
    width: 1300px;
    height: 766px;
    position: relative;
    margin: 0 auto;
}

@media (max-width: 1919px) {
    .container {
        width: 1060px;
        height: 760px;
    }
}

@media (max-width: 1365px) {
    .container {
        width: 700px;
        height: 760px;
    }
}

@media (max-width: 767px) {
    .container {
        width: 328px;
        height: 1109px;
    }
}

.information {
    display: flex;
    justify-content: flex-start;
    height: 236px;
}

@media (max-width: 1919px) {
    .information {
        width: 1060px;
        height: 236px;

    }
}

@media (max-width: 1365px) {
    .information {
        width: 700px;
        height: 247px;
    }
}

@media (max-width: 767px) {
    .information {
        display: block;
        width: 328px;
        height: auto;
    }
}



.information__title {
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #FDFDFD;
    width: 700px;
    margin-top: 100px;
}

@media (max-width: 1919px) {
    .information__title {
        width: 580px
    }
}


@media (max-width: 1365px) {
    .information__title {
        width: 340px;
        font-size: 36px;
        line-height: 49px;
        margin-right: 20px;
    }
}

@media (max-width: 767px) {
    .information__title {
        display: block;
        width: 328px;
        margin-top: 0px;
        padding-top: 70px;
    }
}

.information__text {
    width: 605px;
    height: 112px;
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #FDFDFD;
    margin-top: 118px;
}

@media (max-width: 1919px) {
    .information__text {
        width: 480px;
        height: 88px;
        font-size: 16px;
        line-height: 140%;
    }
}

@media (max-width: 1365px) {
    .information__text {
        width: 340px;
        height: 110px;
        font-size: 16px;
        line-height: 140%;
        margin-top: 113px;
    }
}

@media (max-width: 767px) {
    .information__text {
        width: 300px;
        height: auto;
        margin-top: 0px;
        padding-top: 30px;
    }
}


.form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 55px;
}

@media (max-width: 1919px) {
    .form {}
}

@media (max-width: 1365px) {
    .form {
        padding-top: 45px;
    }
}

@media (max-width: 767px) {
    .form {
        padding-top: 0px;
    }
}

.inputWrapper {
    position: relative;
}

.inputWrapper:nth-child(3) {
    margin-top: 33px;
}

.inputWrapper:nth-child(4) {
    margin-top: 33px;
}

.inputWrapper:nth-child(5) {
    margin-top: 50px;
}

@media (max-width: 767px) {
    .inputWrapper:nth-child(1) {
        border-bottom: 1px solid #FDFDFD;
        padding-top: 33px;
    }
    .inputWrapper:nth-child(2) {
        padding-top: 33px;
        left: 0px;
        top: 90px;
        border-bottom: 1px solid #FDFDFD;

    }
    .inputWrapper:nth-child(3) {
        margin-top: 0px;
        padding-top: 33px;
        border-bottom: 1px solid #FDFDFD;
        left: 0px;
        top: -90px;
    }
    
    .inputWrapper:nth-child(4) {
        padding-top: 33px;
        margin-top: 0px;
        border-bottom: 1px solid #FDFDFD;
    }
    
    .inputWrapper:nth-child(5) {
        padding-top: 50px;
        margin-top: 0px;
        height: auto;
        border-bottom: 1px solid #FDFDFD;
    }
}

.inputError {
    color: #CB4335;
    font-size: 12px;
    position: absolute;
    top: 55px;
}

.inputError__message {
    color: #CB4335;
    font-size: 12px;
    position: absolute;
    top: 85px;
}

.inputError__file {
    color: #CB4335;
    font-size: 12px;
    position: absolute;
    top: 50px;
}

@media (max-width: 767px) {
    .inputError {
        top: 90px;
    }
    
    .inputError__message {
        top: 175px;
    }
    
    .inputError__file {
        top: 100px;
    }
}


.visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}


.form__name,
.form__country,
.form__email,
.form__position,
.form__message {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #A0A0A0;
    width: 600px;
    height: 55px;
    border: none;
    border-bottom: 1px solid #FDFDFD;
    background-color: #303030;
    outline: none;
}

@media (max-width: 1919px) {

    .form__name,
    .form__country,
    .form__email,
    .form__position,
    .form__message {
        font-size: 16px;
        line-height: 140%;
        width: 480px;
    }
}

@media (max-width: 1365px) {

    .form__name,
    .form__country,
    .form__email,
    .form__position,
    .form__message {
        width: 340px;
        font-size: 16px;
        border-bottom: 1px solid #FDFDFD;
    }
}

@media (max-width: 767px) {

    .form__name,
    .form__country,
    .form__email,
    .form__position,
    .form__message {
        width: 328px;
        border: none;
    }
}



.form__country,
.form__position {
    border-bottom: none;
}

.form__name::-moz-placeholder,
.form__email::-moz-placeholder,
.form__message::-moz-placeholder {
    color: #A0A0A0;
}

.form__name::placeholder,
.form__email::placeholder,
.form__message::placeholder {
    color: #A0A0A0;
}






.form__message {
    resize: none;
    outline: none;
    width: 1300px;
    height: 85px;
}

@media (max-width: 1919px) {
    .form__message {
        width: 1060px;
    }
}

@media (max-width: 1365px) {
    .form__message {
        width: 700px;
    }
}

@media (max-width: 767px) {
    .form__message {
        width: 328px;
        height: 120px;
    }
}

.form__message::-webkit-scrollbar {
    display: none;
}

.form__message::-webkit-scrollbar {
    display: none;
}

.form__file {
    display: block;
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    width: auto;
    cursor: pointer;
    color: #28C0E2;
}

@media (max-width: 1919px) {
    .form__file {
        font-size: 16px;
        line-height: 140%;

    }
}

@media (max-width: 1365px) {
    .form__file {}
}

@media (max-width: 767px) {
    .form__file {
        height: auto;
        width: 200px;
    }
}




.form__button {
    position: absolute;
    right: 0;
    bottom: 38px;
    width: 120px;
    height: 120px;
    border: none;
    border-radius: 50%;
    background-color: #28C0E2;
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    transition: .5s;
    cursor: pointer;
    color: #303030;
    box-shadow: 0px 0px 30px #28C0E2;
}

.form__button:hover {
    transform: scale(1.15);
}



@media (max-width: 1919px) {
    .form__button {
        bottom: 38px;
    }
    .form__button:hover {
        transform: scale(1.1);
    }
}

@media (max-width: 767px) {
    .form__button {
        bottom: 50px;
        right: 0px;
    }

    
}

























.select {
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.select.isActive .select__body {
    display: block;
    -webkit-box-shadow: 0px 0px 3px 0px #000;
    box-shadow: 0px 0px 3px 0px #000;
}

.select.isActive .select__icon {
    transform: translateY(-50%) rotateZ(180deg);
}

.select__header {
    cursor: pointer;
    display: flex;
    position: relative;
    height: 55px;
    align-items: center;
    border-bottom: 1px solid #fdfdfd;
    color: #a0a0a0;
}
@media (max-width: 767px) {
    .select__header  {
        border-bottom: none;
    }
}


.select__header>input {
    visibility: hidden;
    position: absolute;
    left: -9999px;
}

.select__current {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #a0a0a0;
}

.select__current-wrapper {
    display: flex;
    flex-direction: column-reverse;
}

.select__icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    background-image: url(../../img/select-arrow.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: ease 0.5s;
}

.select__body {
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 102%;
    z-index: 8000;
    max-height: 230px;
    overflow: auto;
    background-color: #303030;
    opacity: 1;


    scrollbar-color: #525252 #303030;
}

@media (max-width: 1919px) {
    .select__body {
        width: 480px;
    }

    .select__current {
        font-size: 16px;
    }
}

@media (max-width: 1365px) {
    .select__body {
        width: 340px;
    }

}

@media (max-width: 767px) {
    .select__body {
        width: 328px;
    }

}

.select__body::-webkit-scrollbar {
    background-color: #303030;
}

.select__body::-webkit-scrollbar-thumb {
    background-color: #525252;
    border-radius: 10px;
}

.select__body::-webkit-scrollbar-thumb:hover {
    background-color: #757575;
}

.select__item {
    min-height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #fdfdfd;
    background-color: #303030;
    opacity: 1;
    padding: 0px 20px;
}

.select__item-wrapper {
    display: flex;
    flex-direction: column-reverse;
}

.select__item:hover {
    background-color: #28c0e2;
}