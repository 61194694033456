.wrapper {
    width: 1300px;
    height: 1506px;
    margin: 0 auto;
}

@media (max-width: 1919px) {
    .wrapper {
        height: 1246px;
        width: 1060px;
    }
}

@media (max-width: 1365px) {
    .wrapper {
        height: 1098px;
        width: 698px;
    }
}

@media (max-width: 767px) {
    .wrapper {
        height: 1736px;
        width: 328px;
    }
}



.title {
    font-family: 'Satoshi-Bold';
    font-weight: 700;
    font-size: 50px;
    line-height: 67.5px;
    margin-top: 200px;
    color: #303030;
    width: 260px;
}

@media (max-width: 1919px) {
    .title {
        margin-top: 150px;
    }
}

@media (max-width: 1365px) {
    .title {
        margin-top: 100px;
        font-size: 36px;
        line-height: 48.6px;
        width: 190px;
    }
}

@media (max-width: 767px) {
    .title {
        margin-top: 70px;
    }
}


.block {
    width: 1300px;
    height: 300px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.linearAnimation {
    height: 6px;
    width: 100%;
    background: linear-gradient(90deg, #0098EE -7.51%, #BD00FF 105.71%);
    position: absolute;
    top: 0px;
    left: -100%;
    transition: .3s ease;
}

.block:hover .linearAnimation {
    left: 0px;
}

.block:first-of-type {
    margin-top: 70px;
}

.block:last-of-type {
    margin-top: 100px;
}

@media (max-width: 1919px) {
    .block {
        width: 1060px;
        height: 250px;
    }

    .block:first-of-type {
        margin-top: 70px;
    }

    .block:last-of-type {
        margin-top: 70px;
    }
}

@media (max-width: 1365px) {
    .block {
        width: 698px;
        height: 250px;
    }

    .block:first-of-type {
        margin-top: 50px;
    }

    .block:last-of-type {
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .block {
        flex-wrap: wrap;
        width: 328px;
        height: auto;
    }

    .block:first-of-type {
        margin-top: 50px;
    }

    .block:last-of-type {
        margin-top: 50px;
    }
}


.block__img {
    width: 640px;
    height: 300px;
    object-fit: cover;
    object-position: 50% 90%;
}

@media (max-width: 1919px) {
    .block__img {
        width: 520px;
        height: 250px;
    }
}

@media (max-width: 1365px) {
    .block__img {
        width: 338px;
        height: 250px;
    }
}

@media (max-width: 767px) {
    .block__img {
        width: 328px;
        height: 250px;
    }
}


.block__wrapper {
    width: 660px;
    background-color: #303030;
    color: #fdfdfd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

@media (max-width: 1919px) {
    .block__wrapper {
        width: 540px;
    }
}

@media (max-width: 1365px) {
    .block__wrapper {
        width: 360px;
    }
}

@media (max-width: 767px) {
    .block__wrapper {
        width: 328px;
    }
}


.block__title {
    font-family: 'Satoshi-Bold';
    margin-left: 66px;
    font-size: 36px;
    line-height: 49px;
    background: linear-gradient(90deg, #0098EE -7.51%, #BD00FF 105.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media (max-width: 1919px) {
    .block__title {
        margin-left: 59.5px;
        font-size: 24px;
        line-height: 32px;
    }
}

@media (max-width: 1365px) {
    .block__title {
        margin-left: 20px;
    }
}

@media (max-width: 767px) {
    .block__title {
        margin-top: 20px;
        margin-left: 20px;
    }
}

.block__text {
    font-family: 'Satoshi-Regular';
    margin-top: 30px;
    margin-left: 66px;
    width: 528px;
    font-size: 20px;
    line-height: 140%;

}

@media (max-width: 1919px) {
    .block__text {
        width: 421px;
        margin-top: 30px;
        font-size: 16px;
        line-height: 140%;
        font-weight: 400;
        margin-left: 59.5px;

    }
}

@media (max-width: 1365px) {
    .block__text {
        width: 320px;
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media (max-width: 767px) {
    .block__text {
        width: 288px;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 30px;
    }
}


.block_2 {
    width: 1300px;
    height: 300px;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    cursor: pointer;
}

.block_2:hover .linearAnimation {
    left: 0px;
}

@media (max-width: 1919px) {
    .block_2 {
        width: 1060px;
        height: 250px;
        margin-top: 70px;
    }
}

@media (max-width: 1365px) {
    .block_2 {
        width: 698px;
        height: 250px;
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .block_2 {
        width: 328px;
        height: auto;
        flex-wrap: wrap-reverse;
        margin-top: 50px;
    }
}