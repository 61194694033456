.company-subtitle {
  width: 1300px;
  margin: 150px auto 20px auto;
  text-align: left;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  color: #28C0E2;
}

@media (max-width: 1919px) {
  .company-subtitle {
    width: 1060px;
    margin: 100px auto 20px auto;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .company-subtitle {
    width: 700px;
    margin: 70px auto 20px auto;
  }
}

@media (max-width: 767px) {
  .company-subtitle {
    width: 328px;
    margin: 50px auto 20px auto;
  }
}


.company-title-wrapper {
  width: 1300px;
  margin: 0 auto;
}

@media (max-width: 1919px) {
  .company-title-wrapper {
    width: 1060px;
  }
}

@media (max-width: 1365px) {
  .company-title-wrapper {
    width: 700px;
  }
}

@media (max-width: 767px) {
  .company-title-wrapper {
    width: 328px;
  }
}

.company-title {
  width: 745px;
  margin-bottom: 150px;
  font-family: "Satoshi-Black", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 70px;
  line-height: 95px;
}

@media (max-width: 1919px) {
  .company-title {
    width: 790px;
    margin-bottom: 100px;
  }
}

@media (max-width: 1365px) {
  .company-title {
    width: 610px;
    font-size: 55px;
    line-height: 74px;
    margin-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .company-title {
    width: 328px;
    font-size: 45px;
    line-height: 61px;
    margin-bottom: 50px;
    padding-right: 30px;
  }
}

.company-flashlight-wrapper {
  height: 600px;
  width: 100%;
}

@media (max-width: 1919px) {
  .company-flashlight-wrapper {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .company-flashlight-wrapper {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .company-flashlight-wrapper {
    width: 100%;
    height: 300px;
  }
}

.company-img-wrapper {
  position: relative;
  overflow: hidden;
  width: 1920px;
  height: 600px;
  margin: 0 auto;
}


.company-img-top {
  position: absolute;
  max-width: 100%;
  width: 100%;
  height: 600px;
  z-index: -1;
  object-fit: none;

}

.company-img-top--active {
  z-index: 999;
  height: 100%;
  cursor: url(../../img/company_coursor.svg) 60 60, pointer;
}




@media (max-width: 1919px) {
  .company-img-wrapper {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .company-img-wrapper {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .company-img-wrapper {
    width: 100%;
    height: 300px;
  }
}

.company-img {
  height: 600px;
  -o-object-fit: none;
  object-fit: none;
}

@media (max-width: 1919px) {
  .company-img {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .company-img {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .company-img {
    width: 100%;
    height: 300px;
  }
}

.information {
  background-color: #303030;
}

.information-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 1300px;
  height: 900px;
  margin: 0 auto;
}

@media (max-width: 1919px) {
  .information-wrapper {
    width: 1060px;
    height: 700px;
  }
}

@media (max-width: 1365px) {
  .information-wrapper {
    width: 700px;
    height: 550px;
  }
}

@media (max-width: 767px) {
  .information-wrapper {
    display: block;
    width: 328px;
    height: 989px;
  }
}

.information_title_blue {
  color: #28C0E2;
}

.information_title {
  width: 579px;
  height: 406px;
  margin-top: 151px;
  margin-left: 5px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 150px;
  line-height: 203px;
  color: #FDFDFD;
}

@media (max-width: 1919px) {
  .information_title {
    width: 538px;
    height: 298px;
    margin-top: 100px;
    margin-left: 0;
    font-size: 110px;
    line-height: 149px;
  }
}

@media (max-width: 1365px) {
  .information_title {
    width: 270px;
    height: 190px;
    margin-top: 89px;
    font-size: 70px;
    line-height: 95px;
  }
}

@media (max-width: 767px) {
  .information_title {
    width: 328px;
    height: 162px;
    font-size: 60px;
    line-height: 81px;
    margin-top: -50px;
    padding-top: 100px;
  }
}

.information_text-inner {
  margin-top: 230px;
}

@media (max-width: 1919px) {
  .information_text-inner {
    margin-top: 140px;
  }
}

@media (max-width: 1365px) {
  .information_text-inner {
    margin-top: 70px;
  }
}

@media (max-width: 767px) {
  .information_text-inner {
    margin-top: 130px;
  }
}

.information_text {
  width: 640px;
  height: auto;
  margin-left: 76px;
  margin-bottom: 15px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #FDFDFD;
}

@media (max-width: 1919px) {
  .information_text {
    width: 520px;
    margin: 0 0 14px 0;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .information_text {
    width: 430px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 767px) {
  .information_text {
    width: 328px;
    margin-bottom: 13px;
  }
}

.information_list {
  width: 1300px;
  height: 150px;
  margin-top: -50px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

@media (max-width: 1919px) {
  .information_list {
    width: 1060px;
    height: 150px;
  }
}

@media (max-width: 1365px) {
  .information_list {
    width: 700px;
    height: 130px;
    margin-top: -40px;
  }
}

@media (max-width: 767px) {
  .information_list {
    margin-top: 50px;
    width: 329px;
    height: 290px;
    display: flex;
    flex-wrap: wrap;
  }
}

.information_item {
  display: block;
  /*     width: 311px;
*/
  margin-right: 89px;
  border-left: 2px solid #28C0E2;
}

@media (max-width: 1919px) {
  .information_item {
    /*       width: 267px;
*/
    margin-right: 30px;
  }
}

@media (max-width: 1365px) {
  .information_item {
    /*       width: 180px;
*/
    margin-right: 46px;
  }
}

@media (max-width: 767px) {
  .information_item {
    width: 155px;
    height: 130px;
    margin: 0;
    margin-bottom: 29px;
  }

  .information_item:nth-child(2) {
    margin-left: 19px;
  }

  .information_item:nth-child(4) {
    margin-left: 19px;
  }
}

.information_item_subtitle {
  margin-left: 20px;
  margin-top: 13px;
  font-family: "Satoshi-Black", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 70px;
  line-height: 95px;
  color: #FDFDFD;
  -webkit-margin-left: 20px;
  -webkit-margin-top: 13px;
  -webkit-font-family: "Satoshi-Black", sans-serif;
  -webkit-font-style: normal;
  -webkit-font-weight: 900;
  -webkit-font-size: 70px;
  -webkit-line-height: 95px;
  -webkit-color: #FDFDFD;
}

@media (max-width: 1919px) {
  .information_item_subtitle {
    margin-top: 18px;
    -webkit-margin-top: 18px;
  }
}

@media (max-width: 1365px) {
  .information_item_subtitle {
    font-size: 36px;
    line-height: 49px;
    margin-top: 16px;
    margin-bottom: 5px;
    -webkit-font-size: 36px;
    -webkit-line-height: 49px;
    -webkit-margin-top: 16px;
    -webkit-margin-bottom: 5px;
  }
}

.information_item_text {
  margin-left: 20px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #FDFDFD;
  -webkit-margin-left: 20px;
  -webkit-font-family: "Satoshi-Regular", sans-serif;
  -webkit-font-style: normal;
  -webkit-font-weight: 400;
  -webkit-font-size: 20px;
  -webkit-line-height: 140%;
  -webkit-color: #FDFDFD;
}

@media (max-width: 1919px) {
  .information_item_text {
    font-size: 16px;
    line-height: 140%;
    -webkit-font-size: 16px;
    -webkit-line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .information_item_text {
    font-size: 16px;
    line-height: 140%;
    -webkit-font-size: 16px;
    -webkit-line-height: 140%;
  }
}