.value {
    width: 1300px;
    height: 518px;
    margin: 0 auto;
    margin-top: 200px;
  }
  @media (max-width: 1919px) {
    .value {
      width: 1060px;
      height: 438px;
      margin-top: 150px;
    }
  }
  @media (max-width: 1365px) {
    .value {
      width: 700px;
      height: 719px;
      margin-top: 100px;
    }
  }
  @media (max-width: 767px) {
    .value {
      width: 328px;
      height: 1039px;
      margin-top: 70px;
    }
  }
  .value-title {
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
  }
  @media (max-width: 1919px) {
    .value-title {
      margin-left: 3px;
    }
  }
  @media (max-width: 1365px) {
    .value-title {
      font-size: 36px;
      line-height: 49px;
      margin-bottom: 30px;
    }
  }
  .value-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .value-card {
    position: relative;
    width: 420px;
    height: 350px;
    margin-top: 100px;
    background-color: #FFFFFF;
    overflow: hidden;
  }
  .value-card::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 420px;
    height: 2px;
    background-color: #303030;
  }
  @media (max-width: 1919px) {
    .value-card {
      width: 340px;
      height: 300px;
      margin-top: 70px;
    }
  }
  @media (max-width: 1365px) {
    .value-card {
      margin-top: 20px;
    }
  }
  @media (max-width: 767px) {
    .value-card {
      width: 328px;
      height: 300px;
    }
  }
  .value-card-wrapper {
    width: 360px;
    margin: 73px auto 27px auto;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1919px) {
    .value-card-wrapper {
      width: 280px;
      margin: 50px auto 30px auto;
    }
  }
  @media (max-width: 767px) {
    .value-card-wrapper {
      width: 268px;
      margin: 50px auto 30px auto;
    }
  }
  .value-card_title {
    width: 280px;
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    background: linear-gradient(89.61deg, #28C0E2 -4.38%, #212121 106.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }
  @media (max-width: 1919px) {
    .value-card_title {
      width: 240px;
      font-size: 16px;
      line-height: 140%;
    }
  }
  @media (max-width: 767px) {
    .value-card_title {
      width: 212px;
    }
  }
  .value-card_number,
  .value-card_text {
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }
  @media (max-width: 1919px) {
    .value-card_number,
    .value-card_text {
      font-size: 16px;
      line-height: 140%;
    }
  }
  .value-card_text {
    width: 360px;
    margin: 0 auto;
  }
  @media (max-width: 1919px) {
    .value-card_text {
      width: 280px;
    }
  }
  @media (max-width: 767px) {
    .value-card_text {
      width: 268px;
    }
  }
  @media (max-width: 1365px) {
    .value-card-tablet {
      width: 700px;
      height: 300px;
    }
    .value-card-tablet::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 700px;
      height: 2px;
      background-color: #303030;
    }
  }
  @media (max-width: 767px) {
    .value-card-tablet {
      width: 328px;
      height: 300px;
    }
  }
  @media (max-width: 1365px) {
    .value-card-wrapper-tablet {
      width: 640px;
    }
  }
  @media (max-width: 767px) {
    .value-card-wrapper-tablet {
      width: 268px;
      margin: 50px auto 30px auto;
    }
  }
  @media (max-width: 1365px) {
    .value-card-text-tablet {
      width: 580px;
      margin: 0;
      margin-left: 30px;
    }
  }
  @media (max-width: 767px) {
    .value-card-text-tablet {
      width: 268px;
    }
  }