.careers-vacancy {
  width: 1300px;
  height: auto;
  margin: 0 auto;
  margin-top: 194px;
  display: flex;
}

@media (max-width: 1919px) {
  .careers-vacancy {
    width: 1060px;
    height: auto;
    margin-top: 143px;
  }
}

@media (max-width: 1365px) {
  .careers-vacancy {
    width: 700px;
    height: auto;
    margin-top: 95px;
  }
}

@media (max-width: 767px) {
  .careers-vacancy {
    display: block;
    margin-top: 70px;
    width: 328px;
    height: auto;
  }
}

.careers-vacancy-content {
  width: 440px;
}

@media (max-width: 1919px) {
  .careers-vacancy-content {
    width: 360px;
  }
}

@media (max-width: 1365px) {
  .careers-vacancy-content {
    width: 270px;
  }
}

@media (max-width: 767px) {
  .careers-vacancy-content {
    width: 328px;
    height: 310px;
  }
}

.careers-vacancy-title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  margin: 8px 0 30px 0;
}

@media (max-width: 1365px) {
  .careers-vacancy-title {
    width: 230px;
    font-size: 36px;
    line-height: 49px;
    margin: 5px 0 20px 0;
  }
}

.careers-vacancy-text {
  width: 290px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {
  .careers-vacancy-text {
    width: 310px;
    font-size: 16px;
    line-height: 22.4px;
  }
}

@media (max-width: 1365px) {
  .careers-vacancy-text {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .careers-vacancy-text {
    width: 328px;
  }
}

.careers-vacancy-button {
  width: 120px;
  height: 120px;
  margin-top: 50px;
  border-radius: 50%;
  border: none;
  background-color: #28C0E2;
  color: #303030;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  transition: all 500ms ease;
  cursor: pointer;
  box-shadow: 0px 0px 30px #28C0E2;
}

.careers-vacancy-button:hover {
  transform: scale(1.15);
}







.vacancy-card {
  margin-top: 5px;
  margin-right: -7px;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .vacancy-card {
    width: 328px;
    height: auto;
    margin-top: 80px;
  }
}


.careers-vacancy-content-when-no-vacancy {
  width: 440px;
}

@media (max-width: 1919px) {
  .careers-vacancy-content-when-no-vacancy {
    width: 360px;
  }
}

@media (max-width: 1365px) {
  .careers-vacancy-content-when-no-vacancy{
    width: 270px;
  }
}

@media (max-width: 767px) {
  .careers-vacancy-content-when-no-vacancy {
    width: 328px;
    height: 320px;
  }
}



.vacancy-card-when-no-vacancy-wrapper {
  margin-top: 5px;
  margin-right: -7px;
  align-items: flex-start;
  border-left: 1px solid #A0A0A0;
}

@media (max-width: 767px) {
  .vacancy-card-when-no-vacancy-wrapper {
    width: 328px;
    height: auto;
    margin-top: 80px;
  }
}





.vacancy-card-when-no-vacancy {
  height: 180px;
  width: 860px;
  border-bottom: 1px solid #A0A0A0;
  position: relative;
}

@media (max-width: 1919px) {
  .vacancy-card-when-no-vacancy {
    width: 700px;
  }
}

@media (max-width: 1365px) {
  .vacancy-card-when-no-vacancy {
    height: 150px;
    width: 430px;
  }
}

@media (max-width: 767px) {
  .vacancy-card-when-no-vacancy {
    height: 123px;
    width: 328px;
  }
}




.vacancy-card-when-no-vacancy-title {
  font-family: 'Satoshi-Bold';
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  position: absolute;
  left: 30px;
  bottom: 30px;
}

@media (max-width: 1919px) {
  .vacancy-card-when-no-vacancy-title {
    font-size: 16px;
    line-height: 22.4px;
    left: 27px;
    bottom: 30px;
  }
}

@media (max-width: 1365px) {
  .vacancy-card-when-no-vacancy-title {
    left: 27px;
    bottom: 30px;
  }
}

@media (max-width: 767px) {
  .vacancy-card-when-no-vacancy-title {
    left: 29px;
    bottom: 15px;
  }
}


@media (min-width: 768px) {
  .vacancy-card-vertical-line-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .vacancy-card-vertical-line-mobile {
    position: absolute;
    transform: rotate(90deg);
    left: -89px;
    bottom: -40px;
    width: 177px;
    border: none;
    border-bottom: 1px solid #A0A0A0;
  }
}