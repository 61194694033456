.careersCollage1366 {
    position: relative;
    height: 500px;
    overflow: hidden;
    cursor: url(../../../img/move_cursor_white.svg) 60 60, pointer;
}
 @media (max-width: 1919px) {
    .careersCollage1366 {
        height: 500px;
    }
  } 

.careersCollage__img1366 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    min-width: 2778px;
    min-height: 1000px;
}

