.wrapper {
    height: 100%;
    width: 100%;
    z-index: 11000;
    position: fixed;
    top: 0px;
    left: 0px;
    display: block;
}

.shutter {
    background-color: #303030;
    width: 100%;
    height: 50%;
    position: relative;
    top: 0;
    left: 0;
    transition: 0.5s ease;
}

.shutter:first-child {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
    position: relative;
}

.logo {
    width: 70px;
    height: 70px;
}

.logo__animation {
    position: absolute;
    left: 49.8%;
    bottom: 55px;
    height: 30px;
    width: 35px;
    background-color: #303030;

    -webkit-animation: logoAnimation 0.7s linear infinite alternate both;
    animation: logoAnimation 0.7s linear infinite alternate both;
}

@media (max-width: 767px) {
    .logo__animation {
        left: 49.6%;
    }
}

@-webkit-keyframes logoAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes logoAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.shutter:last-child {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.progressBar {
    height: 2px;
    width: 0%;
    background-color: #fdfdfd;
    transition: 3s ease;
}

.bodyOverflowHidden{
    overflow: hidden;
  }