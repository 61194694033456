.companyCollage1920 {
    position: relative;
    height: calc(100vh + 50px);
    overflow: hidden;
    cursor: url(../../../img/move_cursor_black.svg) 60 60, pointer;
    margin-top: 150px;
}

.companyCollage__img1920 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    min-width: 3000px;
    min-height: 2000px;
}

