.industries-card-desktop{
  overflow: hidden;
  background-color: #fdfdfd;
}

@media (max-width: 767px) {
  .industries-card-desktop{
      display: none;
  }
}

.industries-card-mobile{
  display: none;
}
@media (max-width: 767px) {
  .industries-card-mobile{
      display: block;
  }
}


.industries-card {
  width: 420px;
  height: 420px;
  margin-left: 20px;
  border-top: 2px solid #303030;
  background-color: #FFFFFF;
}
@media (max-width: 1919px) {
  .industries-card {
    width: 340px;
    height: 350px;
  }
}
@media (max-width: 1365px) {
  .industries-card {
    width: 340px;
    height: 350px;
  }
}
@media (max-width: 767px) {
  .industries-card {
    width: 328px;
    height: 350px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}



.industries-card_wrapper {
  width: 360px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  margin: 68px auto 30px;
}
@media (max-width: 1919px) {
  .industries-card_wrapper {
    width: 280px;
    height: 40px;
    margin: 50px auto 25px;
  }
}
@media (max-width: 1365px) {
  .industries-card_wrapper {
    width: 280px;
    margin: 50px auto 25px;
  }
}
@media (max-width: 767px) {
  .industries-card_wrapper {
    width: 268px;
    height: auto;
    margin: 50px auto 25px;
  }
}
.industries-card_title {
  width: 280px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  background: linear-gradient(89.61deg, #28C0E2 -4.38%, #212121 106.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1919px) {
  .industries-card_title {
    width: 240px;
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 767px) {
  .industries-card_title {
    width: 212px;
    margin-bottom: -0px;
}
} 
.industries-card_number {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}
@media (max-width: 1919px) {
  .industries-card_number {
    font-size: 16px;
    line-height: 140%;
  }
}
.industries-card-inner {
  width: 420px;
}
@media (max-width: 1919px) {
  .industries-card-inner {
    width: 340px;
    margin-top: 12px;
  }
}
@media (max-width: 767px) {
  .industries-card-inner {
    margin: 0 auto;
    width: 268px;
    margin-top: 25px;
  }
}
.industries-card_text {
  width: 360px;
  margin: 0 auto;
  margin-bottom: 17px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}
@media (max-width: 1919px) {
  .industries-card_text {
    width: 280px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 1365px) {
  .industries-card_text {
    margin-bottom: 11px;
  }
}
@media (max-width: 767px) {
  .industries-card_text {
    margin: 0 auto;
    margin-bottom: 11px;
    width: 268px;
  }
}



.animation-cover-top{
-webkit-animation-name: animation-top;
animation-name: animation-top;
-webkit-animation-duration: 0.38s;
animation-duration: 0.38s;
-webkit-animation-timing-function: ease-in-out;
animation-timing-function: ease-in-out;
-webkit-animation-iteration-count: 1;
animation-iteration-count: 1;
-webkit-animation-direction: normal;
animation-direction: normal;
-webkit-animation-fill-mode: forwards;
animation-fill-mode: forwards;
}
.animation-cover-down{
-webkit-animation-name: animation-down;
animation-name: animation-down;
-webkit-animation-duration: 0.38s;
animation-duration: 0.38s;
-webkit-animation-timing-function: ease-in-out;
animation-timing-function: ease-in-out;
-webkit-animation-iteration-count: 1;
animation-iteration-count: 1;
-webkit-animation-direction: normal;
animation-direction: normal;
-webkit-animation-fill-mode: forwards;
animation-fill-mode: forwards;
}



@keyframes animation-top {
  0% {
    top: 0px;
    left: 0px;
  }
  100% {
    top: -450px;
    left: 0px;
  }
}

@keyframes animation-down {
  0% {
    top: -450px;
    left: 0px;
  }
  100% {
    top: 0px;
    left: 0px;
  }
}



@-webkit-keyframes animation-top {
0% {
  transform: scaleY(1);
  transform-origin: 100% 0%;
}
100% {
  transform: scaleY(0);
  transform-origin: 100% 0%;
}
}
@-webkit-keyframes animation-down {
0% {
  transform: scaleY(0);
  transform-origin: 100% 0%;
}
100% {
  transform: scaleY(1);
  transform-origin: 100% 0%;
}
}
