.ddt-process {
  width: 1300px;
  height: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  margin-top: 200px;
  margin-bottom: 10px;
}

@media (max-width: 1919px) {
  .ddt-process {
    height: 990px;
    width: 1060px;
    margin-top: 150px;
    margin-bottom: 0px;
  }
}

@media (max-width: 1365px) {
  .ddt-process {
    width: 700px;
    height: auto;
    margin-top: 100px;
    display: block;
    margin-bottom: -60px;
  }
}

@media (max-width: 767px) {
  .ddt-process {
    display: block;
    width: 328px;
    height: auto;
    margin-top: 70px;
    margin-bottom: -20px;
  }
}

.ddt-process-img {
  width: 530px;
  height: 800px;
  border-radius: 12px;
  background-color: #E5E5E5;
}

@media (max-width: 1919px) {
  .ddt-process-img {
    width: 430px;
    height: 600px;
  }
}

@media (max-width: 1365px) {
  .ddt-process-img {
    width: 700px;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .ddt-process-img {
    width: 328px;
    height: 300px;
    object-fit: cover;
  }
}

.ddt-process-sticky {
  position: sticky;
  top: 20px;
}



.ddt-process-inform {
  width: 660px;
  height: 800px;
  padding-left: 130px;
}

@media (max-width: 1919px) {
  .ddt-process-inform {
    width: 540px;
    height: auto;
    padding-left: 110px;
  }
}

@media (max-width: 1365px) {
  .ddt-process-inform {
    width: 340px;
    height: auto;
    display: block;
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  .ddt-process-inform {
    width: 328px;
    height: auto;
    margin-top: 49px;
    border: none;
  }
}


.ddt-process-inform_title {
  width: 640px;
  margin-top: 2px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  margin-bottom: 0px;
}

@media (max-width: 1919px) {
  .ddt-process-inform_title {
    width: 520px;
    margin-bottom: 0px;
  }
}

@media (max-width: 1365px) {
  .ddt-process-inform_title {
    width: 520px;
    font-size: 36px;
    line-height: 49px;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .ddt-process-inform_title {
    width: 328px;
    margin-bottom: 20px;
  }
}







.ddt-process-step {
  width: 660px;
  height: 225px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
}

.ddt-process-step:last-child {
  border-bottom: none;
}

.ddt-process-step div:nth-child(1) {
  width: 205px;
}

.ddt-process-step div:nth-child(2) {
  width: 455px;
}



@media (max-width: 1919px) {
  .ddt-process-step {
    width: 520px;
    height: 168px;
  }

  .ddt-process-step:last-child {
    border-bottom: none;
  }

  .ddt-process-step div:nth-child(1) {
    width: 165px;
  }

  .ddt-process-step div:nth-child(2) {
    width: 355px;
  }
}

@media (max-width: 1365px) {
  .ddt-process-step {
    width: 520px;
    height: 168px;
  }
}

@media (max-width: 767px) {
  .ddt-process-step:first-of-type {
    height: 247px;
  }
}

@media (max-width: 767px) {
  .ddt-process-step {
    width: 328px;
    height: 227px;
    display: block;
    padding-top: 20px;
  }
}






.ddt-process-step_number {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  position: relative;
  top: -15px;
}

@media (max-width: 1919px) {
  .ddt-process-step_number {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .ddt-process-step_number {
    top: 0px;
  }
}

.ddt-process-step_title {
  width: 157px;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  position: relative;
  top: 10px;
}

@media (max-width: 1919px) {
  .ddt-process-step_title {
    width: 125px;
    font-size: 16px;
    line-height: 140%;
    top: 5px;
  }
}

@media (max-width: 767px) {
  .ddt-process-step_title {
    width: 320px;
    top: 0px;
    margin-top: 10px;
  }
}

.ddt-process-step_text {
  width: 435px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  position: relative;
  top: 0px;
}

@media (max-width: 1919px) {
  .ddt-process-step_text {
    width: 355px;
    font-size: 16px;
    line-height: 138%;
  }
}

@media (max-width: 767px) {
  .ddt-process-step_text {
    width: 328px;
    margin-top: 10px;
  }
}




@media (min-width: 1366px) {
  .grid_wrapper {
    overflow: inherit;
  }
}

@media (max-width: 1365px) {
  .grid_wrapper {
    overflow: hidden;
  }
}