.outsource-faq {
    width: 1300px;
    height: auto;
    margin: 0 auto;
    margin-top: 200px;
    overflow: hidden;
  }
  @media (max-width: 1919px) {
    .outsource-faq {
      width: 1060px;
      margin-top: 150px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-faq {
      margin-top: 99px;
      width: 700px;
    }
  }
  @media (max-width: 767px) {
    .outsource-faq {
      margin-top: 51px;
      width: 328px;
    }
  }
  .outsource-faq-content-svg {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  @media (max-width: 1365px) {
    .outsource-faq-content-svg {
      right: 23px;
      top: 31px;
    }
  }
  @media (max-width: 767px) {
    .outsource-faq-content-svg {
      right: 15px;
      top: 42px;
    }
  }
  .outsource-faq-content-svg3 {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  @media (max-width: 1365px) {
    .outsource-faq-content-svg3 {
      right: 23px;
      top: 31px;
    }
  }
  @media (max-width: 767px) {
    .outsource-faq-content-svg3 {
      right: 15px;
      top: 56px;
    }
  }
  .outsource-faq-wrapper {
    width: 1080px;
    height: auto;
  }
  @media (max-width: 1919px) {
    .outsource-faq-wrapper {
      width: 880px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-faq-wrapper {
      width: 700px;
    }
  }
  @media (max-width: 767px) {
    .outsource-faq-wrapper {
      width: 328px;
    }
  }
  .outsource-faq-title {
    font-family: "Satoshi-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    margin-bottom: 100px;
  }
  @media (max-width: 1919px) {
    .outsource-faq-title {
      margin-bottom: 70px;
    }
  }
  @media (max-width: 1365px) {
    .outsource-faq-title {
      margin-bottom: 50px;
      font-size: 36px;
      line-height: 49px;
    }
  }