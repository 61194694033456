.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  color: #303030;
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
}
.page {
  margin: 0 auto;
  height: 100%;
  font-size: 14px;
  font-family: "Satoshi-Regular", sans-serif;
  line-height: 18px;
  color: #303030;
  background-color: #FDFDFD;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Satoshi-Regular", sans-serif;
  line-height: 18px;
  color: #303030;
  background-color: #FDFDFD;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  overscroll-behavior-x: contain;
}

.grid_wrapper {
  display: grid;
  grid-template: auto 1fr auto / 100%;
  min-height: 100vh;
  overflow: hidden;
}

.globalClass__bodyOverflowHidden{
  overflow: hidden;
}
