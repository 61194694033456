.wrapper {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.img__front {
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: none;
    cursor: pointer;
}

.img__back {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    -o-object-fit: none;
    object-fit: none;
    cursor: pointer;
}

.img__back:nth-child(1) {
    transition: .06s ease;
    -moz-transition: .03s ease;
}

.img__back:nth-child(2) {
    transition: .08s ease;
    -moz-transition: .04s ease;
}

.img__back:nth-child(3) {
    transition: .09s ease;
    -moz-transition: .045s ease;
}

.img__back:nth-child(4) {
    transition: .096s ease;
    -moz-transition: .05s ease;
}

.img__back:nth-child(5) {
    transition: .1s ease;
    -moz-transition: .055s ease;
}

.img__back:nth-child(6) {
    transition: .108s ease;
    -moz-transition: .06s ease;
}

.loop__start {
    animation-name: loop__start;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

@keyframes loop__start {
    0% {
        clip-path: circle(0px at var(--x) var(--y));
    }

    100% {
        clip-path: circle(100px at var(--x) var(--y));
    }
}

.img__back_active {
    z-index: 10;
    height: 100%;
    width: 100%;
    cursor: pointer;
    -o-object-fit: none;
    object-fit: none;
}