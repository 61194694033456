.careers-text {
  width: 1300px;
  margin: 0 auto;
}

@media (max-width: 1919px) {
  .careers-text {
    width: 1060px;
  }
}

@media (max-width: 1365px) {
  .careers-text {
    width: 700px;
  }
}

@media (max-width: 767px) {
  .careers-text {
    width: 328px;
  }
}

.careers-subtitle {
  margin: 150px auto 20px auto;
  text-align: left;
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  color: #28C0E2;
}

@media (max-width: 1919px) {
  .careers-subtitle {
    width: 1060px;
    margin: 100px auto 20px auto;
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .careers-subtitle {
    width: 700px;
    margin: 70px auto 20px auto;
  }
}

@media (max-width: 767px) {
  .careers-subtitle {
    width: 328px;
    margin: 50px auto 20px auto;
  }
}

.careers-title {
  width: 740px;
  margin-bottom: 150px;
  font-family: "Satoshi-Black", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 70px;
  line-height: 95px;
}

@media (max-width: 1919px) {
  .careers-title {
    width: 740px;
    margin-bottom: 100px;
  }
}

@media (max-width: 1365px) {
  .careers-title {
    width: 580px;
    margin-bottom: 70px;
    font-size: 55px;
    line-height: 74px;
  }
}

@media (max-width: 767px) {
  .careers-title {
    width: 328px;
    font-size: 45px;
    line-height: 61px;
    margin-bottom: 50px;
  }
}

.careers-img {
  display: block;
  width: 1920px;
  height: 600px;
  margin: 0 auto;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 1919px) {
  .careers-img {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .careers-img {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .careers-img {
    width: 100%;
    height: 300px;
  }
}



@media (max-width: 1919px) {
  .collage1920 {
    display: none;
  }
}

@media (min-width: 1920px) {
  .collage1366 {
    display: none;
  }
}
@media (max-width: 1365px) {
  .collage1366 {
    display: none;
  }
}

.collage_tablet{
  margin-bottom: -5px;
} 

@media (min-width: 1366px) {
  .collage_tablet {
    display: none;
  }
}
@media (max-width: 767px) {
  .collage_tablet {
    display: none;
  }
}

.collage_mobile{
  margin-bottom: -5px;
}

@media (min-width: 768px) {
  .collage_mobile {
    display: none;
  }
}



