.services {
  width: 1300px;
  height: 500px;
  margin: 0 auto;
  margin-top: 200px;
  margin-bottom: 200px;
  display: flex;
}

@media (max-width: 1919px) {
  .services {
    width: 1060px;
    margin-top: 145px;
    margin-bottom: 150px;
  }
}

@media (max-width: 1365px) {
  .services {
    display: block;
    width: 700px;
    height: 690px;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .services {
    width: 328px;
    height: 714px;
    margin-top: 63px;
    margin-bottom: 70px;
  }
}

.services-content {
  position: relative;
  width: 640px;
}

@media (max-width: 1919px) {
  .services-content {
    width: 520px;
  }
}

@media (max-width: 1365px) {
  .services-content {
    width: 700px;
    height: 390px;
  }
}

@media (max-width: 767px) {
  .services-content {
    width: 328px;
    height: 450px;
  }
}

.services-title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  width: 640px;
}

@media (max-width: 1919px) {
  .services-title {
    width: 520px;
  }
}

@media (max-width: 1365px) {
  .services-title {
    font-size: 36px;
    line-height: 49px;
    width: 338px;
  }
}

@media (max-width: 767px) {
  .services-title {
    width: 328px;
  }
}

.services-wrapper {
  display: flex;
  height: 54px;
  justify-content: space-between;
  border-bottom: 2px solid #A0A0A0;
  margin: 70px 0 30px;
  position: relative;
}

.services-wrapper span {
  /* background-color: orange;
  margin-bottom: -20px; */
}

@media (max-width: 1919px) {
  .services-wrapper {
    position: relative;
    height: 40px;
    margin: 72px 0 30px;
  }
}

@media (max-width: 1365px) {
  .services-wrapper {
    height: 45px;
    margin: 50px 0 30px;
  }
}

.services-sutitle {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  cursor: pointer;
}

.services-active-line {
  position: absolute;
  bottom: -2px;
  left: 0;
  /* width: 100%; */
  height: 2px;
  background-color: #28C0E2;
  transition: ease .5s;
}

@media (max-width: 1919px) {
  .services-sutitle {
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .services-sutitle {
    font-size: 16px;
    line-height: 140%;
  }
}

.services-sutitle_active {
  color: #28C0E2;
}

.services-text,
.services-link {
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
}

@media (max-width: 1919px) {

  .services-text,
  .services-link {
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {

  .services-text,
  .services-link {
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .services-text {
    width: 609px;
  }
}

@media (max-width: 767px) {
  .services-text {
    width: 328px;
    height: 110px;
  }
}

.services-link {
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: absolute;
  display: block;
  bottom: 0;
  transition: ease .5s;
}

.services-link:hover {
  column-gap: 20px;
}

@media (max-width: 1919px) {
  .services-link {
    bottom: 0px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    transition: ease .5s;
  }

  .services-link:hover {
    column-gap: 20px;
  }
}

@media (max-width: 1365px) {
  .services-link {
    bottom: 25px;
  }
}

.services-link-svg {
  margin-left: 5px;
  margin-bottom: -2px;
}



.services-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  column-gap: 5px;
  bottom: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.services-arrow-svg {
  position: relative;
  top: 0px;
  left: 0px;
  transition: .3s;
}

@media (max-width: 1919px) {
  .services-arrow {
    bottom: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
}

@media (max-width: 1365px) {
  .services-arrow {
    bottom: 25px;
  }
}

.services-arrow:hover .services-arrow-svg {
  top: 0px;
  left: 10px;

}


/* -------Slider-------- */



.slideshow {
  overflow: hidden;
  position: absolute;
  left: 56%;
  width: 840px;
  height: 500px;
}

@media (max-width: 1919px) {
  .slideshow {
    left: 57%;
    width: 583px;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .slideshow {
    display: block;
    left: 0;
    position: relative;
    width: 700px;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .slideshow {
    width: 328px;
    height: 300px;
  }
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease-out 500ms all;
  -webkit-transition: ease-out 500ms all;
}

.slideshowSlider  img {
  width: 840px;
  height: 500px;
}

@media (max-width: 1919px) {
  .slideshowSlider  img  {
    width: 583px;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .slideshowSlider  img  {

    width: 700px;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .slideshowSlider  img  {
    width: 328px;
    height: 300px;
  }
}