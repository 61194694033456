.careersCollage1920 {
    position: relative;
    height: 600px;
    overflow: hidden;
    cursor: url(../../../img/move_cursor_white.svg) 60 60, pointer;
}
/* @media (max-width: 1919px) {
    .careersCollage1920 {
        height: 500px;
    }
  }
  @media (max-width: 1365px) {
    .careersCollage1920 {
        height: 400px;
    }
  }
  @media (max-width: 767px) {
    .careersCollage1920 {
        height: 300px;
    }
  } */

.careersCollage__img1920 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    min-width: 3000px;
    min-height: 1200px;
}

